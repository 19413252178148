const strings = {
    null: {},
    'en': {
        'Why': "An e-wallet that understands you",
        'title1': 'Multi-currency account made for you',
        'body1': 'VaulFi is the only legal and hassle-free option to have a multi-currency account in Algeria. Say goodbye to account closures and the frustration of losing access to your funds.',
        'title2': 'Verify your account using your Algerian documents',
        'body2': 'VaulFi is the first & only e-wallet that accepts Algerian identification documents and utility bills for KYC and account verification.',
        'title3': 'Get paid easily',
        'body3': 'Gain access to your own IBAN and SWIFT code, simplifying international money transfers from anywhere in the world to you.',
        'title4': 'Send & receive money instantly',
        'body4': 'Sending and receiving money with other VaulFi users is as simple as scanning a QR code or copying and pasting a VaulFi user ID.',
        'title5': 'VaulFi card delivered to your doorstep',
        'body5': 'Order your physical card and get it delivered right to your doorstep in Algeria! Say goodbye to relying on favors from friends and family abroad.',
    },
    'fr': {
        'Why': "Un e-wallet qui vous comprend",
        'title1': "Un compte multidevises fait que pour vous",
        'body1': "VaulFi est la seule option légale et sans tracas pour avoir un compte multidevises en Algérie",
        'title2': "Faites vous vérifier votre compte en utilisant vos documents d'identité Algériens",
        'body2': "Vaulfi est le premier et le seul e-wallet qui accept les document d'identité Algérien et les factures de services public pour le KYC et la vérification des comptes",
        'title3': "Soyez payé facilement",
        'body3': "Accédez à votre propre IBAN et code SWIFT, simplifiant ainsi le transfert international de l'argent depuis n'importe où dans le monde",
        'title4': "Envoyez et recevez de l'argent instantanément",
        'body4': "Envoyer et recevoir de l'argent avec d'autres utilisateurs de VaulFi est simple, il suffit de scanner un code QR ou de copier et coller un identifiant d'utilisateur VaulFi.",
        'title5': "Une carte VaulFi livrée à votre porte",
        'body5': "Commandez votre carte physique et faites-la livrer directement à votre adresse en Algérie! Dites adieu aux faveurs de vos amis et de votre famille à l'étranger",
    },
    'ar': {
        'Why': 'المحفظة الرقمية التي تفهمك',
        'title1': 'حساب متعدد العملات مصمم خصيصاً لك',
        'body1': 'ڤولفاي (VaulFi) هو الخيار القانوني الوحيد والخالي من المتاعب للحصول على حساب متعدد العملات في الجزائر. لا خوف بعد اليوم من خسارة أموالك فحساباتك ستبقى دائماً ملكاً لك و لن يتم إغلاقها.',
        'title2': 'فعّل حسابك باستخدام وثائقك الجزائرية',
        'body2': 'محفظة ڤولفاي (VaulFi) الرقمية هي الأولى و الوحيدة التي تقبل كافة وثائق الهوية الجزائرية و فواتير الخدمات لاتمام "تعرّف على عميلك" (KYC) و تفعيل الحساب.',
        'title3': 'تحصّل على مستحاقتك المالية بسهولة',
        'body3': 'احصل على رقم IBAN و رمز SWIFT الخاصين بك، و استقبل التحويلات المالية الخارجية بسهولة من جميع أنحاء العالم.',
        'title4': 'أرسل و استقبل الأموال على الفور',
        'body4': 'يتم إرسال و استقبال الأموال مع مستخدمي ڤولفاي (VaulFi) الآخرين بكل سهولة عن طريق مسح رمز الاستجابة السريعة (QR code) أو نسخ و لصق رمز مستخدم ڤولفاي التعريفي (VaulFi user ID).',
        'title5': 'نقوم بتوصيل بطاقة ڤولفاي (VaulFi) إلى باب منزلك',
        'body5': 'اطلب بطاقتك البلاستيكية و سيتم توصيلها إلى باب منزلك في الجزائر! لن تضطر مجدداً للاعتماد على أقاربك أو أصدقائك المقيمين خارج الجزائر.',
    },
}

export default strings;