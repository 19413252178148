import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import Select from 'react-select';
import Navbar from '../../Components/Navbar/Navbar';
import Language from '../../Modals/Language/Language';
import bootstrap from 'bootstrap/dist/js/bootstrap';
import { particlesConfig } from '../../Assets/particlesConfig';
import strings from './strings';

const Pricing = ({ API_URL }) => {
    const location = useLocation();
    const [ searchParams ] = useSearchParams();
    const [lang, setLang] = useState(null);
    const [currency, setCurrency] = useState('EUR');
    const currencyOptions = [{value: 'USD', label: 'USD 🇺🇸'}, {value: 'EUR', label: 'EUR 🇪🇺'}];
    const currencyOptionsAR = [{value: 'USD', label: 'دولار 🇺🇸'}, {value: 'EUR', label: 'يورو 🇪🇺'}];
    const navigate = useNavigate();
    
    useEffect(() => {
        document.title = 'VaulFi - Fees';

        if (!lang) {
            if (location.state?.lang) {
                setLang(location.state.lang);
            }
            else if (searchParams.get('lang')) {
                setLang(searchParams.get('lang'));
            }
            else {
                let modal = bootstrap.Modal.getOrCreateInstance(document.getElementById('LanguageModal'));
                modal.show();
                window.particlesJS('language-particles', particlesConfig);
            }
        }
        // eslint-disable-next-line
    }, []);

    useEffect(()=> {
        if(document.getElementById('particles-js') !== null) {
            window.particlesJS('particles-js', particlesConfig);
        }
    }, [lang]);

    useEffect(() => {
        let el = document.getElementsByTagName('html')[0];
        if (lang === 'ar') {
            el.setAttribute('dir', 'rtl');
            el.setAttribute('lang', 'ar');
            let newEl = document.createElement('link');
            newEl.setAttribute('rel', 'stylesheet');
            newEl.setAttribute('href', 'https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/css/bootstrap-utilities.rtl.css');
            newEl.setAttribute('crossorigin', 'anonymous');
            newEl.id = 'rtlCSS';
            document.head.appendChild(newEl);
            document.title = 'ڤولفاي (VaulFi) - الرسوم';
        }
        else if (lang === 'fr') {
            el.setAttribute('dir', '');
            el.setAttribute('lang', 'fr');
            document.getElementById('rtlCSS')?.remove();
            document.title = 'VaulFi - Frais';
        }
        else {
            el.setAttribute('dir', '');
            el.setAttribute('lang', 'en');
            document.getElementById('rtlCSS')?.remove();
            document.title = 'VaulFi - Fees';
        }
    }, [lang]);
    
    return (
        <>
        {
            !lang?
            null
            :
            <>
            <Navbar lang={lang} setLang={setLang} pricing={true}/>
            <section id="particles-js" className="container-fluid text-center">
                <div className='cover-2 mt-5 mt-lg-0 pt-5 pt-lg-0'></div>
                <h1 className='mx-auto position-relative z-1'>{strings[lang]['title']}</h1>
                <h3 className='mx-auto position-relative z-1 mb-5'>{strings[lang]['body']}</h3>
                <div className='z-1 position-relative pricing my-auto mx-auto col-12 col-lg-9 col-xl-8 col-xxl-7'>
                    <table className='w-100'>
                        <colgroup>
                            <col style={{width: '70%'}} />
                            <col style={{width: '30%'}} />
                        </colgroup>
                        <tbody>
                            <tr>
                                <td className='d-flex align-items-center text-start px-2 pt-4 mt-3'>
                                    <h3 className='ps-1 ps-sm-3 pb-4'>{strings[lang]['account']}</h3>
                                </td>
                                <td className='right-column'>
                                    <Select
                                        className='mx-2 mx-sm-4 mx-md-5'
                                        isClearable={false}
                                        isSearchable={true}
                                        options={lang === 'ar' ? currencyOptionsAR : currencyOptions}
                                        defaultValue={lang === 'ar' ? currencyOptionsAR[1] : currencyOptions[1]}
                                        onChange={(newValue) => {setCurrency(newValue.value)}}
                                    >
                                    </Select>
                                </td>
                            </tr>
                            <tr>
                                <td className='d-flex flex-column align-items-start text-start p-2'>
                                    <h5 className='ps-1 ps-sm-4'>{strings[lang]['registration']}</h5>
                                </td>
                                <td className='right-column px-5'>
                                    <h5>{strings[lang]['free']}</h5>
                                </td>
                            </tr>
                            <tr>
                                <td className='d-flex flex-column align-items-start text-start p-2'>
                                    <h5 className='ps-1 ps-sm-4'>{strings[lang]['iban']}</h5>
                                </td>
                                <td className='right-column px-5'>
                                    <h5>{strings[lang]['free']}</h5>
                                </td>
                            </tr>
                            <tr>
                                <td className='d-flex flex-column align-items-start text-start p-2 pb-5'>
                                    <h5 className='ps-1 ps-sm-4'>{strings[lang]['maintenance']}</h5>
                                </td>
                                <td className='right-column px-5 pb-5'>
                                    <h5>{strings[lang]['free']}</h5>
                                </td>
                            </tr>

                            <tr className='border-top'>
                                <td className='d-flex align-items-center text-start px-2 pt-4'>
                                    <h3 className='ps-1 ps-sm-3 pb-4'>{strings[lang]['receive']}</h3>
                                </td>
                                <td className='right-column'></td>
                            </tr>
                            <tr>
                                <td className='d-flex flex-column align-items-start text-start p-2'>
                                    <h5 className='ps-1 ps-sm-4'>{strings[lang]['receiveSupportedCurrency']}</h5>
                                </td>
                                <td className='right-column px-5'>
                                    <h5>{strings[lang]['free']}</h5>
                                </td>
                            </tr>
                            <tr>
                                <td className='d-flex flex-column align-items-start text-start p-2 pb-5'>
                                    <h5 className='ps-1 ps-sm-4'>{strings[lang]['receiveOtherCurrency']}</h5>
                                </td>
                                <td className='right-column px-1 px-md-4 px-xxl-5 pb-5'>
                                    <h5>{strings[lang]['currencyConversionFee']}</h5>
                                </td>
                            </tr>
                            
                            <tr className='border-top'>
                                <td className='d-flex align-items-center text-start px-2 pt-4'>
                                    <h3 className='ps-1 ps-sm-3 pb-4'>{strings[lang]['send']}</h3>
                                </td>
                                <td className='right-column'></td>
                            </tr>
                            <tr>
                                <td className='d-flex flex-column align-items-start text-start p-2'>
                                    <h5 className='ps-1 ps-sm-4'>{strings[lang]['sendVaulFi']}</h5>
                                </td>
                                <td className='right-column px-1 px-sm-4 px-md-5'>
                                    <h5>
                                        {
                                            currency === 'USD' ?
                                            '1% + $0.27'
                                            :
                                            currency === 'EUR' ?
                                            '1% + €0.25'
                                            :
                                            null
                                        }
                                    </h5>
                                </td>
                            </tr>
                            <tr>
                                <td className='d-flex flex-column align-items-start text-start p-2 pb-5'>
                                    <h5 className='ps-1 ps-sm-4'>{strings[lang]['sendBank']}</h5>
                                </td>
                                <td className='right-column px-1 px-sm-4 px-md-5 pb-5'>
                                    <h5>
                                        {
                                            currency === 'USD' ?
                                            '2% + $0.27'
                                            :
                                            currency === 'EUR' ?
                                            '2% + €0.25'
                                            :
                                            null
                                        }
                                    </h5>
                                </td>
                            </tr>
                            
                            <tr className='border-top'>
                                <td className='d-flex align-items-center text-start px-2 pt-4'>
                                    <h3 className='ps-1 ps-sm-3 pb-4'>{strings[lang]['card']}</h3>
                                </td>
                                <td className='right-column'></td>
                            </tr>
                            <tr>
                                <td className='d-flex flex-column align-items-start text-start p-2'>
                                    <h5 className='ps-1 ps-sm-4'>{strings[lang]['vCard']}</h5>
                                    <p className='ps-1 ps-sm-4 ps-md-5 pe-3 pe-md-4 mt-0'>{strings[lang]['vCardDetails']}</p>
                                </td>
                                <td className='right-column px-5'>
                                    <h5>
                                        {
                                            currency === 'USD' ?
                                            '$2.13'
                                            :
                                            currency === 'EUR' ?
                                            '€2'
                                            :
                                            null
                                        }
                                    </h5>
                                </td>
                            </tr>
                            <tr>
                                <td className='d-flex flex-column align-items-start text-start p-2 pb-5'>
                                    <h5 className='ps-1 ps-sm-4'>{strings[lang]['pCard']}</h5>
                                    <p className='ps-1 ps-sm-4 ps-md-5 pe-3 pe-md-4 mt-0'>{strings[lang]['pCardDetails']}</p>
                                </td>
                                <td className='right-column px-1 px-sm-3 px-md-4 px-xl-5 pb-5'>
                                    <h5>{strings[lang]['soon']}</h5>
                                </td>
                            </tr>
                            
                            <tr className='border-top'>
                                <td className='d-flex align-items-center text-start px-2 pt-4'>
                                    <h3 className='ps-1 ps-sm-3 pb-4'>{strings[lang]['spend']}</h3>
                                </td>
                                <td className='right-column'></td>
                            </tr>
                            <tr>
                                <td className='d-flex flex-column align-items-start text-start p-2'>
                                    <h5 className='ps-1 ps-sm-4'>{strings[lang]['transactionSupportedCurrency']}</h5>
                                </td>
                                <td className='right-column px-5'>
                                    <h5>{strings[lang]['free']}</h5>
                                </td>
                            </tr>
                            <tr>
                                <td className='d-flex flex-column align-items-start text-start p-2'>
                                    <h5 className='ps-1 ps-sm-4'>{strings[lang]['transactionOtherCurrency']}</h5>
                                </td>
                                <td className='right-column px-1 px-md-4 px-xxl-5'>
                                <h5>{strings[lang]['currencyConversionFee']}</h5>
                                </td>
                            </tr>
                            <tr>
                                <td className='d-flex flex-column align-items-start text-start p-2 pb-5'>
                                    <h5 className='ps-1 ps-sm-4'>{strings[lang]['fundingTransactions']}</h5>
                                    <p className='ps-1 ps-sm-4 ps-md-5 pe-3 pe-md-4 mt-0'>{strings[lang]['fundingDetails']}</p>
                                </td>
                                <td className='right-column px-1 px-sm-4 px-md-5 pb-5'>
                                    <h5>
                                        {
                                            currency === 'USD' ?
                                            '2% + $0.27'
                                            :
                                            currency === 'EUR' ?
                                            '2% + €0.25'
                                            :
                                            null
                                        }
                                    </h5>
                                </td>
                            </tr>

                        </tbody>
                    </table>
                </div>
                <div className='mt-4 mb-5'>
                    <button className="btn btn-lg btn-primary main-btn position-relative z-1 text-center" onClick={() => {navigate(`/register`, {state: {lang: lang}});}}>{strings[lang]['buttonText']}</button>
                </div>
            </section>
            </>
        }
        <Language setLang={setLang}/>
        </>
    );
};

export default Pricing;