import React, { useState, useEffect } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { particlesConfig } from '../../Assets/particlesConfig';
import strings from "./strings";
import Navbar from '../../Components/Navbar/Navbar';
import Language from '../../Modals/Language/Language';
import bootstrap from 'bootstrap/dist/js/bootstrap';
import { Icon } from '@iconify/react';

const Contact = ({ API_URL }) => {
    const location = useLocation();
    const [ searchParams ] = useSearchParams();
    const [lang, setLang] = useState(null);
    const [step, setStep] = useState(1);
    
    useEffect(() => {
        document.title = 'VaulFi - Contact Us';

        if (!lang) {
            if (location.state?.lang) {
                setLang(location.state.lang);
            }
            else if (searchParams.get('lang')) {
                setLang(searchParams.get('lang'));
            }
            else {
                let modal = bootstrap.Modal.getOrCreateInstance(document.getElementById('LanguageModal'));
                modal.show();
                window.particlesJS('language-particles', particlesConfig);
            }
        }
        
        // eslint-disable-next-line
    }, []);

    useEffect(()=> {
        if(document.getElementById('particles-js') !== null) {
            window.particlesJS('particles-js', particlesConfig);
        }
    }, [lang]);

    useEffect(() => {
        let el = document.getElementsByTagName('html')[0];
        if (lang === 'ar') {
            el.setAttribute('dir', 'rtl');
            el.setAttribute('lang', 'ar');
            let newEl = document.createElement('link');
            newEl.setAttribute('rel', 'stylesheet');
            newEl.setAttribute('href', 'https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/css/bootstrap-utilities.rtl.css');
            newEl.setAttribute('crossorigin', 'anonymous');
            newEl.id = 'rtlCSS';
            document.head.appendChild(newEl);
            document.title = 'ڤولفاي (VaulFi) - تواصل معنا';
        }
        else if (lang === 'fr') {
            el.setAttribute('dir', '');
            el.setAttribute('lang', 'fr');
            document.getElementById('rtlCSS')?.remove();
            document.title = 'VaulFi - Contactez Nous';
        }
        else {
            el.setAttribute('dir', '');
            el.setAttribute('lang', 'en');
            document.getElementById('rtlCSS')?.remove();
            document.title = 'VaulFi - Contact Us';
        }
    }, [lang]);

    function sendMessage() {
        const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const name = document.getElementById('cName');
        const email = document.getElementById('cEmail');
        const message = document.getElementById('cMessage');
        
        name.classList.remove('is-invalid');
        email.classList.remove('is-invalid');
        message.classList.remove('is-invalid');
        
        let valid = true;
        if(!name.value || !email.value || !message.value) {
            valid = false;
            toast.error(`${strings[lang][step]['error']}`);
            if (!name.value) {
                name.classList.add('is-invalid');
            }
            if (!email.value) {
                email.classList.add('is-invalid');
            }
            if (!message.value) {
                message.classList.add('is-invalid');
            }
        }

        if (email.value && !regexp.test(email.value)) {
            valid = false;
            toast.error(`${strings[lang][step]['emailError']}`);
            email.classList.add('is-invalid');
        }

        if (valid === true) {
            let url = `${API_URL}/log/message`;
            const data = {'name': name.value, 'email': email.value, 'message': message.value};
            const headers = {'Content-Type': 'application/json'};
            
            fetch(url, {method: 'POST', body: JSON.stringify(data), headers: headers})
            .then((res) => res.json())
            .then((data) => {
                if (data.status === 'Success') {
                    setStep(2);
                }
            })
            .catch(console.log);
        }
    }

    return (
        <>
        {
        !lang ?
        null
        :
        <>
            <Navbar lang={lang} setLang={setLang} contact={true}/>
            <section id="particles-js" className={`${step === 3 ? 'd-flex flex-column align-items-center' : 'container-fluid text-center'}`}>
                {
                    step === 1 || step === 2 ?
                    <div className='cover-2 mt-5 mt-lg-0 pt-5 pt-lg-0'></div>
                    :
                    <div className="mt-5" style={{height:'1px'}}></div>
                }
                {
                    step === 1 ?
                    <div className={`z-1 position-relative redeem my-auto mx-auto py-3 col-sm-8 col-lg-5`}>
                        <div className='d-flex flex-column text-center'>
                            <h2 className='mx-auto complete-main'>{strings[lang][step]['contact']}</h2>
                            <div className='mx-auto mt-1 d-flex flex-column align-items-center gap-5 mb-3'>
                                <label htmlFor="paymentMethod" className={`${lang === 'ar' ? 'fs-5' : ''} text-center mx-auto mt-4`}>{strings[lang][step]['label']}</label>
                                
                                <div className='d-flex flex-column align-items-center gap-3'>
                                    <a className='btn btn-lg btn-outline-dark contact-btn d-flex align-items-center justify-content-center gap-2' href="https://wa.me/message/6UAXW3A54SJMI1" target="_blank" rel="noreferrer">
                                        <Icon icon="jam:whatsapp" /> {strings[lang][step]['whatsapp']}
                                    </a>

                                    {/* <button className="btn btn-lg btn-outline-dark topup-btn mb-3 on-particles" onClick={() => {handlePaymentChange('other');}}>
                                        <h2 className="d-flex align-items-center justify-content-center secondary-font m-auto gap-2 topup-text">{strings[lang][step]['other']}</h2>
                                    </button> */}
                                    
                                    <button className="btn btn-lg btn-outline-dark contact-btn" onClick={() => {setStep(2)}}>
                                        {strings[lang][step]['request']}
                                    </button>
                                </div>

                                <div className='d-flex flex-column align-items-center gap-1'>
                                    <h4 className='secondary-font'>{strings[lang][step]['email']}</h4>
                                    <a className='btn btn-lg btn-outline-dark contact-btn' href="mailto:support@vaulfi.com" target="_blank" rel="noreferrer"> support@vaulfi.com</a>
                                </div>
                                
                                <div className='d-flex flex-column align-items-center gap-1'>
                                    <h4 className='secondary-font'>{strings[lang][step]['socials']}</h4>
                                    <div className="d-flex">
                                        <a href="https://www.facebook.com/vaulfi" target="_blank" rel="noreferrer">
                                            <Icon className='social-icon' icon="jam:facebook-circle" />
                                        </a>
                                        <a href="https://www.instagram.com/vaulfi" target="_blank" rel="noreferrer">
                                            <Icon className='social-icon' icon="jam:instagram" />
                                        </a>
                                        <a href="https://twitter.com/VaulFi" target="_blank" rel="noreferrer">
                                            <Icon className='social-icon' icon="jam:twitter-circle" />
                                        </a>
                                        <a href="https://www.linkedin.com/company/vaulfi" target="_blank" rel="noreferrer">
                                            <Icon className='social-icon' icon="jam:linkedin-circle" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div className='col-12 col-sm-10 col-md-9 col-lg-7 col-xl-6 justify-content-center p-3 p-sm-5 contact-container mx-auto my-auto'>
                        <div className="z-1 d-flex flex-column contact-form align-items-center justify-content-center text-center py-2 px-3 px-sm-5">
                            {
                                step === 2 ?
                                    <>
                                    <h2 className='z-1'>{strings[lang][step]['contact']}</h2>
                                    <p className='z-1 mt-0 mb-5'>{strings[lang][step]['label']}</p>
                                    <input type="text" className='z-1 form-control mb-4' id='cName' key='nameInput' placeholder={strings[lang][step]['name']} />
                                    <input type="text" className='z-1 form-control mb-4' id='cEmail' key='emailInput' placeholder={strings[lang][step]['email']} />
                                    <textarea className='z-1 form-control mb-4' cols="30" rows="10" name="message" id="cMessage" key='messageInput' placeholder={strings[lang][step]['message']}></textarea>
                                    <button className="z-1 btn btn-lg btn-primary" onClick={sendMessage}>{strings[lang][step]['submit']}</button>
                                    </>
                                :
                                    <>
                                    <h2 className={`z-1 mx-auto mt-3 complete-main px-2 ${lang === 'fr' ? 'secondary-font' : ''}`}>{strings[lang][step]['successTitle']}</h2>
                                    <div className='z-1 mx-auto mt-4 mb-5 d-flex flex-column w-75'>
                                        <p className='z-1'>{strings[lang][step]['successMessage']}</p>
                                    </div>
                                    </>
                            }
                        </div>
                    </div>
                }
            </section>
        </>
        }
            <Language setLang={setLang}/>
        </>
    )
};

export default Contact;