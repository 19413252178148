const strings = {
    null : {},
    'en': {
        'Home': 'Home',
        'Product': 'Product',
        'Pricing': 'Fees',
        'Contact': 'Contact us',
        'btn': 'Join Now',
    },
    'fr': {
        'Home': 'Accueil',
        'Product': 'Produit',
        'Pricing': 'Frais',
        'Contact': 'Contactez nous',
        'btn': 'Rejoindre Maintenant',
    },
    'ar': {
        'Home': 'الرئيسية',
        'Product': 'ما نقدمه',
        'Pricing': 'الرسوم',
        'Contact': 'تواصل معنا',
        'btn': 'انضم الآن',
    },
}

export default strings;