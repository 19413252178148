const strings = {
    'en': {
        1: {
            'title': 'Join VaulFi',
            'email': 'Email address',
            'emailValid': '* Looks good!',
            'emailInvalid': '* Please enter a valid email',
            'name': 'Full name',
            'wilaya': 'Wilaya',
            'phone': 'Mobile number',
            'phoneValid': '* Looks good!',
            'phoneInvalid': '* Please enter a valid phone number',
            'category': 'Account type',
            'amount': 'How much money are you receiving monthly?',
            'buttonText': 'Submit',
            'error': 'Please fill all fields correctly',
        },
        2: {
            'title': 'Your First Top-up',
            'method': 'How would you like to top-up your balance?',
            'other': ' Other',
        },
        3: {
            'title': 'Your First Top-up',
            'amount': 'How much would you like to top-up?',
            'buttonText': 'Submit',
            'error': 'Please enter a valid amount',
        },
        4: {
            'successTitle': 'You are on the waitlist',
            'dear': 'Dear',
            'successMessage1': "Thank you for joining VaulFi.\n\nDue to the high demand we're experiencing, we are onboarding new users in batches. We will reach out to you on the email address and phone number you provided once your turn comes.",
            'successMessage2': "ALL WAITLIST USERS WILL RECEIVE 5% ADDITIONAL DISCOUNT",
            'successMessage3': "on transfer fees for 3 months as compensation.\n\nThank you for your patience.\n\nSpeak soon!\nVaulFi Team",
        },
    },
    'fr': {
        1: {
            'title': 'Rejoignez VaulFi',
            'email': 'Adresse e-mail',
            'emailValid': '* Parfait!',
            'emailInvalid': '* Veuillez ressaisir une adresse e-mail valide',
            'name': 'Nom et prénom',
            'wilaya': 'Wilaya',
            'phone': 'Numéro de portable',
            'phoneValid': '* Parfait!',
            'phoneInvalid': '* Veuillez saisir un numéro de portable valide',
            'category': 'Le type du compte',
            'amount': "Combien d'argent recevez-vous mensuellement?",
            'buttonText': 'Soumettre',
            'error': 'veuillez remplir tous les champs correctement',
        },
        2: {
            'title': 'Votre Première Recharge',
            'method': 'Comment souhaitez-vous recharger votre solde?',
            'other': ' Autres',
        },
        3: {
            'title': 'Votre Première Recharge',
            'amount': 'Quel est le montant que vous souhaitez recharger?',
            'buttonText': 'Soumettre',
            'error': 'Veuillez saisir un montant valide',
        },
        4: {
            'successTitle': "Vous êtes sur la liste d'attente",
            'dear': 'Cher/Chère',
            'successMessage1': "Merci d'avoir rejoint VaulFi.\n\nEn raison de la forte demande, nous intégrons les nouveaux utilisateurs par lots. Nous vous contacterons à l'adresse e-mail et au numéro de téléphone que vous avez fournis lorsque votre tour viendra.",
            'successMessage2': "Tous les utilisateurs de la liste d'attente bénéficieront d'une réduction supplémentaire de 5%",
            'successMessage3': "sur les frais de transfert pendant 3 mois en guise de compensation.\n\nNous vous remercions de votre patience.\n\nA bientôt!\nL'équipe VaulFi",
        },
    },
    'ar': {
        1: {
            'title': 'انضم إلى ڤولفاي (VaulFi)',
            'email': 'البريد الإلكتروني',
            'emailValid': '* البريد الإلكتروني صحيح',
            'emailInvalid': '* يرجى إدخال بريد إلكتروني صحيح',
            'name': 'الاسم الكامل',
            'wilaya': 'الولاية',
            'phone': 'رقم الهاتف المحمول',
            'phoneValid': '* رقم الهاتف المحمول صحيح',
            'phoneInvalid': '* يرجى إدخال رقم هاتف محمول صحيح',
            'category': 'نوع الحساب',
            'amount': 'كم تستقبل من الأموال شهرياً؟',
            'buttonText': 'أرسل',
            'error': 'يرجى ملئ جميع الحقول بشكل صحيح.',
        },
        2: {
            'title': 'تعبئتك الأولى للرصيد',
            'method': 'كيف تود تعبئة رصيدك؟',
            'other': ' أخرى',
        },
        3: {
            'title': 'تعبئتك الأولى للرصيد',
            'amount': 'كم هو المبلغ الذي تود تعبئته؟',
            'buttonText': 'أرسل',
            'error': 'يرجى إدخال مبلغ صحيح',
        },
        4: {
            'successTitle': 'أنت في قائمة الانتظار',
            'dear': 'عزيزي/عزيزتي',
            'successMessage1': "شكراً لانضمامك إلى ڤولفاي (VaulFi).\n\nنظراً للطلب الكبير الذي نشهده، فإننا نقوم بتفعيل حسابات المستخدمين الجدد على دفعات. سنقوم بالتواصل معك عبر البريد الإلكتروني و رقم الهاتف الذين قمت بتزويدنا بهما عندما يحل دورك.",
            'successMessage2': "سيتحصل جميع المستخدمين في قائمة الانتظار على خصم إضافي قدره 5%",
            'successMessage3': "على رسوم التحويلات لمدة 3 أشهر كتعويض.\n\nنشكركم على الانتظار.\n\nلنتحدث قريباً!\nفريق ڤولفاي (VaulFi)",
        },
    },
}

export default strings;