import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import ReactGA from "react-ga4";
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap';
import 'particles.js/particles.js';
import './index.css';
import './Assets/fonts/Aeonik-Pro.woff2';
import './Assets/fonts/MD-Nichrome-Test.otf';
import './Assets/fonts/Inter-Regular.woff2';
import Home from './Pages/Home/Home';
import reportWebVitals from './reportWebVitals';
import { clarity } from 'react-microsoft-clarity';
/* import {loadStripe} from '@stripe/stripe-js';
import OrderComplete from './Pages/OrderComplete/OrderComplete';
import Redeem from './Pages/Redeem/Redeem'; */
import Contact from './Pages/Contact/Contact';
import Page404 from './Pages/404/404';
import Signup from './Pages/Signup/Signup';
import Pricing from './Pages/Pricing/Pricing';

const API_URL = process.env.REACT_APP_API_URL || 'http://192.168.1.212:8000';
/* const PP_CLIENT_ID = process.env.REACT_APP_PP_CLIENT_ID || 'AdGPx4CGmf6rM9mbHbjDBLhxzuUK86T6tiovGa2Fr0bXsrDfeSwf0F-X7jZQ-4NwhOczxheTxnpf3qTe';
const STRIPE_CLIENT_ID = process.env.REACT_APP_STRIPE_CLIENT_ID || 'pk_test_51OqL26FlEAggJlEYyKdfdjkSiKkknavdNdBhxw46Mow17W497XMXrjCtT1YU9lqhWDVp594vjHW2EyYB87EsRafO005KeAH8jJ'; */
const GA_TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID || 'G-V7WPDGPSKE';
const CLARITY_ID = process.env.REACT_APP_CLARITY_ID || 'lcxin1um9m';
const MODE = process.env.REACT_APP_MODE || 'dev';

if (MODE === 'prod'){
    clarity.init(CLARITY_ID);
    ReactGA.initialize(GA_TRACKING_ID);
}
else {
    ReactGA.initialize(GA_TRACKING_ID, {gaOptions: {debug_mode: true}, gtagOptions: {debug_mode: true}});
}

/* const stripePromise = loadStripe(STRIPE_CLIENT_ID); */

const router = createBrowserRouter([
    {
        path: '/',
        element:<Home API_URL={API_URL}/>,
    },
    /* {
        path: '/order-complete',
        element: <OrderComplete API_URL={API_URL} />
    },
    {
        path: '/redeem',
        element: <Redeem API_URL={API_URL} />
    }, */
    {
        path: '/register',
        element:<Signup API_URL={API_URL} />,
    },
    {
        path: '/pricing',
        element: <Pricing API_URL={API_URL} />
    },
    {
        path: '/contact',
        element: <Contact API_URL={API_URL} />
    },
    {
        path: '*',
        element: <Page404/>
    },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <>
        <ToastContainer position='top-right' theme='colored'/>
        <RouterProvider router={router} />
    </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
