import React, { useEffect, useState } from "react";
import strings from "./strings";
import mockup1 from '../../Assets/images/mockup1.png';
import mockup2 from '../../Assets/images/mockup2.png';
import mockup3 from '../../Assets/images/mockup3.png';
import mockup4 from '../../Assets/images/mockup4.png';
import mockup5 from '../../Assets/images/mockup5.png';

const Why = ({lang}) => {
    const[isMobile, setIsMobile] = useState(window.innerWidth < 992);

    function handleWindowSizeChange() {
        if(window.innerWidth < 992) {
            setIsMobile(true);
        }
        else {
            setIsMobile(false);
        }
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);

        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        };
        
        // eslint-disable-next-line
    }, []);

    return (
        <section id='Product' className='container-fluid pb-5 px-0'>
            <div className='cover'></div>
            <div className="row align-items-center text-center px25">
                <div className='mt-5 mb-5'>
                    <h1 className='mx-auto'>{strings[lang]['Why']}</h1>
                </div>
            </div>
            <div className="d-flex flex-column align-items-center justify-content-center gap-0 mb-5">
                {
                    isMobile ?
                        <div className="why row row-cols-1 row-cols-lg-2 justify-content-center align-items-center text-center py-5 mb-3 px25">
                            <div className="col d-flex flex-column flex-lg-row">
                                <img src={mockup1} className="img-fluid why-img mb-5" alt='mockup 1'/>
                            </div>
                            <div className="col d-flex flex-column">
                                <h3 className="secondary-font">{strings[lang]['title1']}</h3>
                                <p>{strings[lang]['body1']}</p>
                            </div>
                        </div>
                    :
                        <div className="why row row-cols-1 row-cols-lg-2 justify-content-center align-items-center text-center py-5 mb-3 w-100 px25">
                            <div className="col col-lg-8 d-flex flex-column">
                                <h2 className="secondary-font">{strings[lang]['title1']}</h2>
                                <p className="fs-5">{strings[lang]['body1']}</p>
                                {/* <p className="fs-4 secondary-font px-5 mx-5 mb-5">{strings[lang]['trustedBody']}</p> */}
                            </div>
                            <div className="col col-lg-4 d-flex flex-column flex-lg-row">
                                <img src={mockup1} className="img-fluid why-img mx-auto" alt='mockup 1'/>
                            </div>
                        </div>
                }

                <div className="why why-secondary row row-cols-1 row-cols-lg-2 justify-content-center align-items-center text-center w-100 py-5 mb-3 px25">
                    <div className="col col-lg-4 d-flex flex-column flex-lg-row">
                        {
                            isMobile ?
                                <img src={mockup2} className="img-fluid why-img mb-5" alt='mockup 2'/>
                            :
                                <img src={mockup2} className="img-fluid why-img mx-auto" alt='mockup 2'/>
                        }
                    </div>
                    <div className="col col-lg-8 d-flex flex-column">
                        {
                            isMobile ?
                            <>
                                <h3 className="secondary-font">{strings[lang]['title2']}</h3>
                                <p>{strings[lang]['body2']}</p>
                            </>
                            :
                            <>
                                <h2 className="secondary-font">{strings[lang]['title2']}</h2>
                                <p className="fs-5">{strings[lang]['body2']}</p>
                            </>
                        }
                    </div>
                </div>

                {
                    isMobile ?
                        <div className="why row row-cols-1 row-cols-lg-2 justify-content-center align-items-center text-center py-5 mb-3 px25">
                            <div className="col d-flex flex-column flex-lg-row">
                                <img src={mockup3} className="img-fluid why-img mb-5" alt='mockup 3'/>
                            </div>
                            <div className="col d-flex flex-column">
                                <h3 className="secondary-font">{strings[lang]['title3']}</h3>
                                <p>{strings[lang]['body3']}</p>
                            </div>
                        </div>
                    :
                        <div className="why row row-cols-1 row-cols-lg-2 justify-content-center align-items-center text-center py-5 mb-3 w-100 px25">
                            <div className="col col-lg-8 d-flex flex-column">
                                <h2 className="secondary-font">{strings[lang]['title3']}</h2>
                                <p className="fs-5">{strings[lang]['body3']}</p>
                            </div>
                            <div className="col col-lg-4 d-flex flex-column flex-lg-row">
                                <img src={mockup3} className="img-fluid why-img mx-auto" alt='mockup 3'/>
                            </div>
                        </div>
                }
                
                <div className="why why-secondary row row-cols-1 row-cols-lg-2 justify-content-center align-items-center text-center py-5 mb-3 w-100 px25">
                    <div className="col col-lg-4 d-flex flex-column flex-lg-row">
                        {
                            isMobile ?
                                <img src={mockup4} className="img-fluid why-img mb-5" alt='mockup 4'/>
                            :
                                <img src={mockup4} className="img-fluid why-img mx-auto" alt='mockup 4'/>
                        }
                    </div>
                    <div className="col col-lg-8 d-flex flex-column">
                        {
                            isMobile ?
                            <>
                                <h3 className="secondary-font">{strings[lang]['title4']}</h3>
                                <p>{strings[lang]['body4']}</p>
                            </>
                            :
                            <>
                                <h2 className="secondary-font">{strings[lang]['title4']}</h2>
                                <p className="fs-5">{strings[lang]['body4']}</p>
                            </>
                        }
                    </div>
                </div>

                {
                    isMobile ?
                        <div className="why row row-cols-1 row-cols-lg-2 justify-content-center align-items-center text-center py-5 mb-5 px25">
                            <div className="col d-flex flex-column flex-lg-row">
                                <img src={mockup5} className="img-fluid why-img mb-5" alt='mockup 5'/>
                            </div>
                            <div className="col d-flex flex-column">
                                <h3 className="secondary-font">{strings[lang]['title5']}</h3>
                                <p>{strings[lang]['body5']}</p>
                            </div>
                        </div>
                    :
                        <div className="why row row-cols-1 row-cols-lg-2 justify-content-center align-items-center text-center py-5 w-100 px25">
                            <div className="col col-lg-8 d-flex flex-column">
                                <h2 className="secondary-font">{strings[lang]['title5']}</h2>
                                <p className="fs-5">{strings[lang]['body5']}</p>
                            </div>
                            <div className="col col-lg-4 d-flex flex-column flex-lg-row">
                                <img src={mockup5} className="img-fluid why-img mx-auto" alt='mockup 5'/>
                            </div>
                        </div>
                }

            </div>
        </section>
    );
};

export default Why;