export const categories = {
    'en': [
        {value: 'Business', label: 'Business'},
        {value: 'Freelancer', label: 'Freelancer'},
        {value: 'Individual', label: 'Individual'},
        {value: 'Trader (stocks and more)', label: 'Trader (stocks and more)'},
    ],
    'fr': [
        {value: 'Entreprise', label: 'Entreprise'},
        {value: 'Auto-entrepreneur', label: 'Auto-entrepreneur'},
        {value: 'Individu', label: 'Individu'},
        {value: 'Trader (stocks et plus)', label: 'Trader (stocks et plus)'},
    ],
    'ar': [
        {value: 'شركة', label: 'شركة'},
        {value: 'مقاول ذاتي', label: 'مقاول ذاتي'},
        {value: 'شخصي', label: 'شخصي'},
        {value: 'تاجر (أسهم و غيرها)', label: 'تاجر (أسهم و غيرها)'},
    ]
}