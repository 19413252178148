import React, { useEffect } from 'react';
import strings from './strings';

const Page404 = () => {
    useEffect(() => {
        document.title = 'VaulFi - 404 Page Not Found';
        // eslint-disable-next-line
    }, []);

    return (
        <div className='d-flex flex-column align-items-center justify-content-center text-center w-100' style={{height: '100vh'}}>
            <h1 className='typed-font secondary-font oops'>{strings['oops']}</h1>
            <h3>{strings['404']}</h3>
            <p className='mt-0 mx-3'>{strings['error']}</p>
            <div className='mt-4'>
                <button className="btn btn-lg btn-primary main-btns text-center" onClick={() => {document.location.replace('/')}}>{strings['home']}</button>
            </div>
        </div>
    );
};

export default Page404;