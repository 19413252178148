import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Navbar from '../../Components/Navbar/Navbar';
import Language from '../../Modals/Language/Language';
import Why from '../Why/Why';
import strings from './strings';
import bootstrap from 'bootstrap/dist/js/bootstrap';
import { particlesConfig } from '../../Assets/particlesConfig';

const Home = ({API_URL}) => {
    const [lang, setLang] = useState(null);
    const [ searchParams ] = useSearchParams();
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        document.title = 'VaulFi - The E-wallet for Algerians';

        if (document.location.hash !== '') {
            document.location.replace(document.location.href);
        }

        if (location.state?.emailFocus || searchParams.get('emailFocus') === 'true') {
            document.getElementById('emailInput')?.focus();
        }

        if (!lang) {
            if (location.state?.lang) {
                setLang(location.state.lang);
            }
            else if (searchParams.get('lang')) {
                setLang(searchParams.get('lang'));
            }
            else {
                let modal = bootstrap.Modal.getOrCreateInstance(document.getElementById('LanguageModal'));
                modal.show();
                window.particlesJS('language-particles', particlesConfig);
            }
        }

        // eslint-disable-next-line
    }, []);

    useEffect(()=> {
        if (document.location.hash !== '') {
            document.location.replace(document.location.href);
        }
        
        if (location.state?.emailFocus || searchParams.get('emailFocus') === 'true') {
            document.getElementById('emailInput')?.focus();
        }

        if(document.getElementById('particles-js') !== null) {
            window.particlesJS('particles-js', particlesConfig);
        }
    }, [lang, location.state?.emailFocus, searchParams]);

    useEffect(() => {
        let el = document.getElementsByTagName('html')[0];
        if (lang === 'ar') {
            el.setAttribute('dir', 'rtl');
            el.setAttribute('lang', 'ar');
            let newEl = document.createElement('link');
            newEl.setAttribute('rel', 'stylesheet');
            newEl.setAttribute('href', 'https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/css/bootstrap-utilities.rtl.css');
            newEl.setAttribute('crossorigin', 'anonymous');
            newEl.id = 'rtlCSS';
            document.head.appendChild(newEl);
            document.title = 'ڤولفاي (VaulFi) - المحفظة الرقمية الخاصة بالجزائريين';
        }
        else if (lang === 'fr') {
            el.setAttribute('dir', '');
            el.setAttribute('lang', 'fr');
            document.getElementById('rtlCSS')?.remove();
            document.title = 'VaulFi - Le E-wallet pour les Algériens';
        }
        else {
            el.setAttribute('dir', '');
            el.setAttribute('lang', 'en');
            document.getElementById('rtlCSS')?.remove();
            document.title = 'VaulFi - The E-wallet for Algerians';
        }
    }, [lang]);

    function clearEmail() {
        const email = document.getElementById('emailInput');
        const emailText = document.getElementById('emailText');
        emailText.textContent = '';
        emailText.classList.remove('text-danger');
        emailText.classList.remove('text-success');
        email.classList.remove('is-valid');
        email.classList.remove('is-invalid');
    }

    function validateEmail() {
        const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const email = document.getElementById('emailInput');
        const emailText = document.getElementById('emailText');

        if(!email.value) {
            clearEmail();
            return false;
        }
        
        if (!regexp.test(email.value)) {
            emailText.textContent = strings[lang]['emailInvalid'];
            emailText.classList.add('text-danger');
            emailText.classList.remove('text-success');
            email.classList.add('is-invalid');
            email.classList.remove('is-valid');
            return false;
        }
        else {
            emailText.textContent = strings[lang]['emailValid'];
            emailText.classList.remove('text-danger');
            emailText.classList.add('text-success');
            email.classList.add('is-valid');
            email.classList.remove('is-invalid');
            return true;
        }
    }

    function handleSubmit() {
        const email = document.getElementById('emailInput');
        if (validateEmail()) {
            let url = `${API_URL}/log/email`;
            const data = {'email': email.value, 'language': lang};
            const headers = {'Content-Type': 'application/json'};

            fetch(url, {method: 'POST', body: JSON.stringify(data), headers: headers})
            .then(res => res.json())
            .then(data => {
                if (data.status === 'Success') {
                    navigate('/register', {state: {lang: lang, email: email.value}});
                }
                else {
                    alert(data.message); //TODO: update, although it should never happen
                }
            })
            .catch(console.log);
        }
        else {
            const emailText = document.getElementById('emailText');
            emailText.textContent = strings[lang]['emailInvalid'];
            emailText.classList.add('text-danger');
            emailText.classList.remove('text-success');
            email.classList.add('is-invalid');
            email.classList.remove('is-valid');
            email.focus();
            toast.error(`${strings[lang]['emailError']}`);
        }
    }

    return(
        <>
        {
        !lang ?
            null
        :
        <>
            <Navbar lang={lang} setLang={setLang} home={true} />
            <section id="Home" className='container-fluid pb-5'>
                <div id="particles-js" style={{height: '100%', width: '100%'}}>
                    <div className='cover'></div>
                    <div className="row align-items-center text-center">
                        <div className='mt-5 mb-4'>
                            <h1 className={`main mx-auto position-relative z-1 ${lang === 'fr' ? 'secondary-font' : ''}`}>{strings[lang]['main']}</h1>
                        </div>
                    </div>
                    <div className="d-flex flex-column text-center align-items-center justify-content-center mx-auto gap-0 mt-4">
                        <h3 className="mx-auto position-relative z-1">{strings[lang]['uvp1']}</h3>
                        <h3 className="mx-auto position-relative z-1">{strings[lang]['uvp2']}</h3>
                        <h3 className="mx-auto position-relative z-1">{strings[lang]['uvp3']}</h3>
                    </div>
                    <div className='mt-5 text-center'>
                        <p className='position-relative z-1'>
                            {strings[lang]['join']}
                            <br/>
                            <br/>
                            <b>{strings[lang]['join2']}</b>
                        </p>
                    </div>
                    {/* <div className='mt-3 mt-lg-5 pt-3 pt-lg-5 text-center'>
                        <p>{strings[lang]['join']}</p>
                    </div> */}
                    <div className='col-auto'>
                        <input type="email" className='mx-auto form-control text-start position-relative z-1' id='emailInput' key='emailInput' placeholder={strings[lang]['email']} onChange={clearEmail} onBlur={validateEmail} required/>
                        <div className='form-text mt-1 mb-2 text-center position-relative z-1' id='emailText'></div>
                    </div>
                    <div className="d-flex flex-column flex-lg-row justify-content-center align-items-center text-center mt-3 gap-2 gap-lg-5">
                        <button className="btn btn-lg btn-primary main-btn mb-3 mx-5 position-relative z-1" onClick={handleSubmit}>{strings[lang]['btn']}</button>
                    </div>
                </div>
            </section>
            <Why lang={lang} />
        </>
        }
            <Language setLang={setLang}/>
        </>
    );
};

export default Home;

/* MVP 2 CODE
 useEffect(() => {
        if (document.location.hash !== '') {
            document.location.replace(document.location.href);
        }

        const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
        //[...tooltipTriggerList].map(tooltipTriggerEl => new Tooltip(tooltipTriggerEl));
        [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl));

        window.particlesJS('particles-js', particlesConfig);

        const typed = new Typed(el.current, {
            strings: ['Wise', 'RedotPay', 'Paysera', 'Payoneer', 'Visa', 'Mastercard'],
            typeSpeed: 50,
            smartBackspace: false,
            startDelay: 150,
            backSpeed: 60,
            backDelay: 1e3,
            loop: true,
            loopCount: 1/0,
            onLastStringBackspaced: () => typed.strPos = 0,
        });

        document.onscroll = function() {
            const nav = document.querySelector('#navBar');
            const navLinks = document.querySelectorAll('.nav-link');
            const brand = document.querySelector('.navbar-brand');
            if (brand.classList.contains('secondary')) {
                brand.children[0].src = logo_main;
            }
            else {
                brand.children[0].src = logo_secondary;
            }
            const secondaryIDs = [1, 2];
            const navbarCTA = document.getElementById('navbarCTA');

            document.querySelectorAll('section').forEach((el, key) => {
                if(el.getBoundingClientRect().top <= 25 && el.getBoundingClientRect().bottom >= 56) {
                    if(!secondaryIDs.includes(key)) {
                        navbarCTA.hidden = false;
                        nav.classList.remove('secondary-bg');
                        brand.classList.remove('secondary');
                        navLinks.forEach((navLink) => {
                            navLink.classList.remove('secondary');
                            if (navLink.tagName === 'A' && navLink.href.includes(el.id)) {
                                navLink.classList.add('active');
                            }
                            else {
                                navLink.classList.remove('active');
                            }
                        });
                    }
                    else {
                        if (el.id === 'GiftCards' || el.id === 'Checkout' || el.id === 'OrderComplete') {
                            navbarCTA.hidden = true;
                        }
                        else {
                            navbarCTA.hidden = false;
                        }
                        nav.classList.add('secondary-bg');
                        brand.classList.add('secondary');
                        navLinks.forEach((navLink) => {
                            navLink.classList.add('secondary');
                            if (navLink.tagName === 'A' && navLink.href.includes(el.id)) {
                                navLink.classList.add('active');
                                navLink.classList.remove('secondary');
                            }
                            else {
                                navLink.classList.remove('active');
                            }
                        });
                    }
                }
            });
        }

        return () => {
            // Destroy Typed instance during cleanup to stop animation
            typed.destroy();
        };
        // eslint-disable-next-line
    }, []);
<PayPalScriptProvider options={{ clientId: PP_CLIENT_ID, currency: 'USD', components: 'buttons'}}>
                <Navbar lang={lang} setLang={setLang} home={true} />
                <div>
                    <section id="Home" className='container-fluid pb-5'>
                        <div id="particles-js" style={{height: '100%', width: '100%'}}>
                            <div className='cover'></div>
                            <div className="row align-items-center text-center">
                                <div className='mt-5 mb-4'>
                                    <h1 className='main mx-auto'>{strings[lang]['main1']} <br className='show-mobile'/><span className='typed-font' id="typed" ref={el}>{strings[lang]['main2']}</span></h1>
                                </div>
                            </div>
                            <div className="d-flex flex-column flex-lg-row align-items-center justify-content-center mx-auto gap-2 gap-lg-5">
                                <h3 className='d-flex align-items-center mb-3 pe-lg-5 me-lg-5'>
                                    <Icon icon="eos-icons:trusted-organization" className='home-icon'/> {strings[lang]['trusted']}
                                </h3>
                                <h3 className='d-flex align-items-center gap-2 mb-3 px-lg-5 mx-lg-5'>
                                    <img src={checkLockIcon} alt="check-lock" className='home-icon'/> {strings[lang]['secured']}
                                </h3>
                                <h3 className='d-flex align-items-center gap-2 mb-3 ps-lg-5 ms-lg-5'>
                                    <img src={conflictIcon} alt="check-lock" className='home-icon'/> {strings[lang]['fast']}
                                </h3>
                            </div>
                            <div className="d-flex flex-column flex-lg-row justify-content-center align-items-center text-center mt-4 gap-2 gap-lg-5">
                                <button className="btn btn-lg btn-primary main-btn mb-3 mx-5 on-particles" onClick={() => {document.getElementById('GiftCardsLink').click()}}>{strings[lang]['btn1']}</button>
                                <button className="btn btn-lg btn-outline-light main-btn mb-3 mx-5 on-particles" onClick={() => {document.location.replace('#How')}}>{strings[lang]['btn2']}</button>
                            </div>
                            <div className='mt-3 mt-lg-5 pt-3 pt-lg-5 fs-3 text-center'>
                                <p>{strings[lang]['payment']}</p>
                            </div>
                            <div className='d-flex align-items-center justify-content-center gap-3 gap-lg-5 mx-auto mt-4 align-items-center text-center'>
                                <div className="payment-method d-flex align-items-center justify-content-center on-particles" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="PayPal">
                                    <Icon icon="logos:paypal" className='payment-icon mx-auto my-auto'/>
                                </div>
                                <div className="payment-method d-flex align-items-center justify-content-center on-particles" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="Wise">
                                    <Icon icon="simple-icons:wise" className='payment-icon mx-auto my-auto' style={{color: '#163300'}}/>
                                </div>
                                <div className="payment-method d-flex align-items-center justify-content-center on-particles" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="RedotPay">
                                    <img src={redotIcon} className='payment-icon mx-auto my-auto' alt='redotIcon'/>
                                </div>
                                <div className="payment-method d-flex align-items-center justify-content-center on-particles" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="Paysera">
                                    <img src={payseraIcon} className='payment-icon mx-auto my-auto' alt='payseraIcon'/>
                                </div>
                                <div className="payment-method d-flex align-items-center justify-content-center on-particles" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="Payoneer">
                                    <img src={payoneerIcon} className='payment-icon mx-auto my-auto' alt='payoneerIcon'/>
                                </div>
                                <div className="payment-method d-flex align-items-center justify-content-center on-particles" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="Visa">
                                    <FaCcVisa className='payment-icon mx-auto my-auto' style={{color: '#172BD3'}}/>
                                </div>
                                <div className="payment-method d-flex align-items-center justify-content-center on-particles" data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="Mastercard">
                                    <Icon icon="logos:mastercard" className='payment-icon mx-auto my-auto' />
                                </div>
                            </div>
                        </div>
                    </section>
                    <GiftCards lang={lang} API_URL={API_URL} stripePromise={stripePromise}/>
                    <How lang={lang}/>
                    <Why lang={lang} />
                </div>
            </PayPalScriptProvider>
*/
