const strings = {
    null: {},
    'en': {
        'main': 'The E-wallet for Algerians',
        'uvp1': 'Receive money in EUR & USD',
        'uvp2': 'Keep your funds safe & secure',
        'uvp3': 'Send money to anywhere in the world',
        'join': 'Join 1,000+ Algerians and secure your foreign currency account today',
        'join2': 'First 2,000 users get 5% off on all transactions*',
        'email': 'Email',
        'emailValid': '* Looks good!',
        'emailInvalid': '* Please enter a valid email',
        'emailError': 'Please enter a valid email',
        'btn': 'Join Now',
    },
    'fr': {
        'main': 'Le E-wallet pour les Algériens',
        'uvp1': "Recevez de l'argent en EUR et en USD",
        'uvp2': 'Gardez vos fonds en sécurité',
        'uvp3': "Envoyer de l'argent à n'importe où dans le monde",
        'join': "Rejoignez 1,000+ Algériens et garantissez votre compte en devises dès aujourd'hui",
        'join2': 'Les premiers 2000 utilisateurs bénéficieront de 5% de réduction sur toutes les transactions*',
        'email': 'E-mail',
        'emailValid': '* Parfait!',
        'emailInvalid': '* Veuillez saisir une adresse e-mail valide',
        'emailError': 'Veuillez saisir une adresse e-mail valide',
        'btn': 'Rejoindre Maintenant',
    },
    'ar': {
        'main': 'المحفظة الرقمية الخاصة بالجزائريين',
        'uvp1': 'استقبل الأموال باليورو و الدولار',
        'uvp2': 'احتفظ بأموالك بأمان',
        'uvp3': 'أرسل الأموال إلى جميع أنحاء العالم',
        'join': 'انضم لأكثر من 1000 جزائري و قم بتأمين حسابك الخاص بالعملة الأجنبية اليوم',
        'join2': 'سيحصل أول 2000 مستخدم على 5% خصم على جميع المعاملات*',
        'email': 'البريد الإلكتروني',
        'emailValid': '* البريد الإلكتروني صحيح',
        'emailInvalid': '* يرجى إدخال بريد إلكتروني صحيح',
        'emailError': 'يرجى إدخال بريد إلكتروني صحيح',
        'btn': 'انضم الآن',
    },
}

export default strings;

/*
'en': {
          'main1': 'Buy CCP Gift Cards Using',
          'main2': 'PayPal',
          'trusted': 'Trusted',
          'secured': 'Secure',
          'fast': 'Fast',
          'btn1': 'Buy Now',
          'btn2': 'How it Works',
          'payment': 'We accept various payment options',
    },
*/
