/* let fs = require('fs');
let data = JSON.parse(fs.readFileSync('./wilaya.json', 'utf8'));
let wilayaFrSet = new Set();
let wilayaFr = [];
let wilayaAr = [];
data.forEach(element => {
    if (!wilayaFrSet.has(element.wilaya_name_ascii)) {
        wilayaFrSet.add(element.wilaya_name_ascii);
        wilayaFr.push({value: element.wilaya_name_ascii, label: element.wilaya_name_ascii});
        wilayaAr.push({value: element.wilaya_name, label: element.wilaya_name});
    }
});
console.log(wilayaFr.length, wilayaAr.length);
fs.writeFileSync('./wilayas.json',
JSON.stringify({
    'en': [],
    'fr': wilayaFr,
    'ar': wilayaAr,
}, null, 4)); */

export const wilayas = {
    'en': [
        {
            value: "Adrar",
            label: "Adrar"
        },
        {
            value: "Chlef",
            label: "Chlef"
        },
        {
            value: "Laghouat",
            label: "Laghouat"
        },
        {
            value: "Oum El Bouaghi",
            label: "Oum El Bouaghi"
        },
        {
            value: "Batna",
            label: "Batna"
        },
        {
            value: "Béjaïa",
            label: "Béjaïa"
        },
        {
            value: "Biskra",
            label: "Biskra"
        },
        {
            value: "Béchar",
            label: "Béchar"
        },
        {
            value: "Blida",
            label: "Blida"
        },
        {
            value: "Bouira",
            label: "Bouira"
        },
        {
            value: "Tamanrasset",
            label: "Tamanrasset"
        },
        {
            value: "Tébessa",
            label: "Tébessa"
        },
        {
            value: "Tlemcen",
            label: "Tlemcen"
        },
        {
            value: "Tiaret",
            label: "Tiaret"
        },
        {
            value: "Tizi Ouzou",
            label: "Tizi Ouzou"
        },
        {
            value: "Alger",
            label: "Alger"
        },
        {
            value: "Djelfa",
            label: "Djelfa"
        },
        {
            value: "Jijel",
            label: "Jijel"
        },
        {
            value: "Sétif",
            label: "Sétif"
        },
        {
            value: "Saïda",
            label: "Saïda"
        },
        {
            value: "Skikda",
            label: "Skikda"
        },
        {
            value: "Sidi Bel Abbès",
            label: "Sidi Bel Abbès"
        },
        {
            value: "Annaba",
            label: "Annaba"
        },
        {
            value: "Guelma",
            label: "Guelma"
        },
        {
            value: "Constantine",
            label: "Constantine"
        },
        {
            value: "Médéa",
            label: "Médéa"
        },
        {
            value: "Mostaganem",
            label: "Mostaganem"
        },
        {
            value: "M'Sila",
            label: "M'Sila"
        },
        {
            value: "Mascara",
            label: "Mascara"
        },
        {
            value: "Ouargla",
            label: "Ouargla"
        },
        {
            value: "Oran",
            label: "Oran"
        },
        {
            value: "El Bayadh",
            label: "El Bayadh"
        },
        {
            value: "Illizi",
            label: "Illizi"
        },
        {
            value: "Bordj Bou Arreridj",
            label: "Bordj Bou Arreridj"
        },
        {
            value: "Boumerdès",
            label: "Boumerdès"
        },
        {
            value: "El Tarf",
            label: "El Tarf"
        },
        {
            value: "Tindouf",
            label: "Tindouf"
        },
        {
            value: "Tissemsilt",
            label: "Tissemsilt"
        },
        {
            value: "El Oued",
            label: "El Oued"
        },
        {
            value: "Khenchela",
            label: "Khenchela"
        },
        {
            value: "Souk Ahras",
            label: "Souk Ahras"
        },
        {
            value: "Tipaza",
            label: "Tipaza"
        },
        {
            value: "Mila",
            label: "Mila"
        },
        {
            value: "Aïn Defla",
            label: "Aïn Defla"
        },
        {
            value: "Naâma",
            label: "Naâma"
        },
        {
            value: "Aïn Témouchent",
            label: "Aïn Témouchent"
        },
        {
            value: "Ghardaïa",
            label: "Ghardaïa"
        },
        {
            value: "Relizane",
            label: "Relizane"
        },
        {
            value: "Timimoun",
            label: "Timimoun"
        },
        {
            value: "Bordj Badji Mokhtar",
            label: "Bordj Badji Mokhtar"
        },
        {
            value: "Ouled Djellal",
            label: "Ouled Djellal"
        },
        {
            value: "Béni Abbès",
            label: "Béni Abbès"
        },
        {
            value: "In Salah",
            label: "In Salah"
        },
        {
            value: "In Guezzam",
            label: "In Guezzam"
        },
        {
            value: "Touggourt",
            label: "Touggourt"
        },
        {
            value: "Djanet",
            label: "Djanet"
        },
        {
            value: "El Meghaier",
            label: "El Meghaier"
        },
        {
            value: "El Meniaa",
            label: "El Meniaa"
        }
    ],
    'fr': [
        {
            value: "Adrar",
            label: "Adrar"
        },
        {
            value: "Chlef",
            label: "Chlef"
        },
        {
            value: "Laghouat",
            label: "Laghouat"
        },
        {
            value: "Oum El Bouaghi",
            label: "Oum El Bouaghi"
        },
        {
            value: "Batna",
            label: "Batna"
        },
        {
            value: "Béjaïa",
            label: "Béjaïa"
        },
        {
            value: "Biskra",
            label: "Biskra"
        },
        {
            value: "Béchar",
            label: "Béchar"
        },
        {
            value: "Blida",
            label: "Blida"
        },
        {
            value: "Bouira",
            label: "Bouira"
        },
        {
            value: "Tamanrasset",
            label: "Tamanrasset"
        },
        {
            value: "Tébessa",
            label: "Tébessa"
        },
        {
            value: "Tlemcen",
            label: "Tlemcen"
        },
        {
            value: "Tiaret",
            label: "Tiaret"
        },
        {
            value: "Tizi Ouzou",
            label: "Tizi Ouzou"
        },
        {
            value: "Alger",
            label: "Alger"
        },
        {
            value: "Djelfa",
            label: "Djelfa"
        },
        {
            value: "Jijel",
            label: "Jijel"
        },
        {
            value: "Sétif",
            label: "Sétif"
        },
        {
            value: "Saïda",
            label: "Saïda"
        },
        {
            value: "Skikda",
            label: "Skikda"
        },
        {
            value: "Sidi Bel Abbès",
            label: "Sidi Bel Abbès"
        },
        {
            value: "Annaba",
            label: "Annaba"
        },
        {
            value: "Guelma",
            label: "Guelma"
        },
        {
            value: "Constantine",
            label: "Constantine"
        },
        {
            value: "Médéa",
            label: "Médéa"
        },
        {
            value: "Mostaganem",
            label: "Mostaganem"
        },
        {
            value: "M'Sila",
            label: "M'Sila"
        },
        {
            value: "Mascara",
            label: "Mascara"
        },
        {
            value: "Ouargla",
            label: "Ouargla"
        },
        {
            value: "Oran",
            label: "Oran"
        },
        {
            value: "El Bayadh",
            label: "El Bayadh"
        },
        {
            value: "Illizi",
            label: "Illizi"
        },
        {
            value: "Bordj Bou Arreridj",
            label: "Bordj Bou Arreridj"
        },
        {
            value: "Boumerdès",
            label: "Boumerdès"
        },
        {
            value: "El Tarf",
            label: "El Tarf"
        },
        {
            value: "Tindouf",
            label: "Tindouf"
        },
        {
            value: "Tissemsilt",
            label: "Tissemsilt"
        },
        {
            value: "El Oued",
            label: "El Oued"
        },
        {
            value: "Khenchela",
            label: "Khenchela"
        },
        {
            value: "Souk Ahras",
            label: "Souk Ahras"
        },
        {
            value: "Tipaza",
            label: "Tipaza"
        },
        {
            value: "Mila",
            label: "Mila"
        },
        {
            value: "Aïn Defla",
            label: "Aïn Defla"
        },
        {
            value: "Naâma",
            label: "Naâma"
        },
        {
            value: "Aïn Témouchent",
            label: "Aïn Témouchent"
        },
        {
            value: "Ghardaïa",
            label: "Ghardaïa"
        },
        {
            value: "Relizane",
            label: "Relizane"
        },
        {
            value: "Timimoun",
            label: "Timimoun"
        },
        {
            value: "Bordj Badji Mokhtar",
            label: "Bordj Badji Mokhtar"
        },
        {
            value: "Ouled Djellal",
            label: "Ouled Djellal"
        },
        {
            value: "Béni Abbès",
            label: "Béni Abbès"
        },
        {
            value: "In Salah",
            label: "In Salah"
        },
        {
            value: "In Guezzam",
            label: "In Guezzam"
        },
        {
            value: "Touggourt",
            label: "Touggourt"
        },
        {
            value: "Djanet",
            label: "Djanet"
        },
        {
            value: "El Meghaier",
            label: "El Meghaier"
        },
        {
            value: "El Meniaa",
            label: "El Meniaa"
        }
    ],
    'ar': [
        {
            value: "أدرار",
            label: "أدرار"
        },
        {
            value: " الشلف",
            label: " الشلف"
        },
        {
            value: "الأغواط",
            label: "الأغواط"
        },
        {
            value: "أم البواقي",
            label: "أم البواقي"
        },
        {
            value: "باتنة",
            label: "باتنة"
        },
        {
            value: " بجاية",
            label: " بجاية"
        },
        {
            value: "بسكرة",
            label: "بسكرة"
        },
        {
            value: "بشار",
            label: "بشار"
        },
        {
            value: "البليدة",
            label: "البليدة"
        },
        {
            value: "البويرة",
            label: "البويرة"
        },
        {
            value: "تمنراست",
            label: "تمنراست"
        },
        {
            value: "تبسة",
            label: "تبسة"
        },
        {
            value: "تلمسان",
            label: "تلمسان"
        },
        {
            value: "تيارت",
            label: "تيارت"
        },
        {
            value: "تيزي وزو",
            label: "تيزي وزو"
        },
        {
            value: "الجزائر",
            label: "الجزائر"
        },
        {
            value: "الجلفة",
            label: "الجلفة"
        },
        {
            value: "جيجل",
            label: "جيجل"
        },
        {
            value: "سطيف",
            label: "سطيف"
        },
        {
            value: "سعيدة",
            label: "سعيدة"
        },
        {
            value: "سكيكدة",
            label: "سكيكدة"
        },
        {
            value: "سيدي بلعباس",
            label: "سيدي بلعباس"
        },
        {
            value: "عنابة",
            label: "عنابة"
        },
        {
            value: "قالمة",
            label: "قالمة"
        },
        {
            value: "قسنطينة",
            label: "قسنطينة"
        },
        {
            value: "المدية",
            label: "المدية"
        },
        {
            value: "مستغانم",
            label: "مستغانم"
        },
        {
            value: "المسيلة",
            label: "المسيلة"
        },
        {
            value: "معسكر",
            label: "معسكر"
        },
        {
            value: "ورقلة",
            label: "ورقلة"
        },
        {
            value: "وهران",
            label: "وهران"
        },
        {
            value: "البيض",
            label: "البيض"
        },
        {
            value: "إليزي",
            label: "إليزي"
        },
        {
            value: "برج بوعريريج",
            label: "برج بوعريريج"
        },
        {
            value: "بومرداس",
            label: "بومرداس"
        },
        {
            value: "الطارف",
            label: "الطارف"
        },
        {
            value: "تندوف",
            label: "تندوف"
        },
        {
            value: "تيسمسيلت",
            label: "تيسمسيلت"
        },
        {
            value: "الوادي",
            label: "الوادي"
        },
        {
            value: "خنشلة",
            label: "خنشلة"
        },
        {
            value: "سوق أهراس",
            label: "سوق أهراس"
        },
        {
            value: "تيبازة",
            label: "تيبازة"
        },
        {
            value: "ميلة",
            label: "ميلة"
        },
        {
            value: "عين الدفلة",
            label: "عين الدفلة"
        },
        {
            value: "النعامة",
            label: "النعامة"
        },
        {
            value: "عين تيموشنت",
            label: "عين تيموشنت"
        },
        {
            value: "غرداية",
            label: "غرداية"
        },
        {
            value: "غليزان",
            label: "غليزان"
        },
        {
            value: "تيميمون",
            label: "تيميمون"
        },
        {
            value: "برج باجي مختار",
            label: "برج باجي مختار"
        },
        {
            value: "أولاد جلال",
            label: "أولاد جلال"
        },
        {
            value: "بني عباس",
            label: "بني عباس"
        },
        {
            value: "عين صالح",
            label: "عين صالح"
        },
        {
            value: "عين قزام",
            label: "عين قزام"
        },
        {
            value: "تقرت",
            label: "تقرت"
        },
        {
            value: "جانت",
            label: "جانت"
        },
        {
            value: "المغير",
            label: "المغير"
        },
        {
            value: "المنيعة",
            label: "المنيعة"
        }
    ]
}