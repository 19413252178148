import React, {useEffect, useState} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import strings from './strings';
import { Icon } from '@iconify/react';
import logo_main from '../../Assets/logos/logo_main.png';
import logo_secondary from '../../Assets/logos/logo_secondary.png';
import logo_hover from '../../Assets/logos/logo_hover.png';

const Navbar = ({lang, setLang, home, contact, signup, pricing}) => {
    const [collapsed, setCollapsed] = useState(true);
    const [scrolled, setScrolled] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        document.onscroll = () => {
            if (document.body.scrollTop >= 56 || document.documentElement.scrollTop >= 56) {
                setScrolled(true);
            }
            else {
                setScrolled(false);
            }
            
            if (!home) {
                return;
            }
            const navLinks = document.querySelectorAll('.nav-link');
            document.querySelectorAll('section').forEach((el, key) => {
                if(el.getBoundingClientRect().top <= 25 && el.getBoundingClientRect().bottom >= 56) {
                    navLinks.forEach((navLink) => {
                        if (navLink.tagName === 'A' && navLink.href.includes(el.id)) {
                            navLink.classList.add('active');
                        }
                        else {
                            navLink.classList.remove('active');
                        }
                    });
                }
            });
        };

        // eslint-disable-next-line
    }, []);

    function handleClickCTA() {
        if (document.location.pathname === '/') {
            document.getElementById('emailInput').focus();
        }
        else {
            navigate(`/register`, {state: {lang: lang}});
        }
    }

    return(
        <nav className={`navbar fixed-top navbar-expand-lg w-100 ${scrolled ? 'secondary-bg' : ''}`} id='navBar' >
            <div className="container-fluid">
                <Link
                    className={`navbar-brand ${scrolled ? 'secondary' : ''} ${lang === 'ar' ? 'ms-0' : ''}`}
                    to={`/`}
                    state={{lang: lang}}
                    onMouseOver={(e) => {e.currentTarget.children[0].src = logo_hover}}
                    onMouseOut={(e) => {scrolled ? e.currentTarget.children[0].src = logo_main : e.currentTarget.children[0].src = logo_secondary}}
                    reloadDocument={true}
                >
                    <img src={scrolled ? logo_main : logo_secondary} alt="Vaulfi Logo" className="d-inline-block align-text-top" style={{maxHeight: '24px'}}/>
                    {' VaulFi'}
                </Link>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className={`navbar-nav mx-auto mb-2 mb-lg-0 ${lang === 'ar' ? 'px-0' : ''}`}>
                        <li className="nav-item">
                            {
                            home ?
                            <a className={`nav-link active ${scrolled ? 'secondary' : ''}`} href='#Home'>{strings[lang]['Home']}</a>
                            :
                            <Link className={`nav-link ${scrolled ? 'secondary' : ''}`} state={{lang: lang}} to={{pathname: '/', hash: 'Home'}}>{strings[lang]['Home']}</Link>
                            }
                        </li>
                        {/* <li className="nav-item">
                            <a className={`nav-link ${scrolled ? 'secondary' : ''}`} id="GiftCardsLink" href={home ? '#GiftCards' : '/#GiftCards'}>{strings[lang]['Cards']}</a>
                        </li>
                        <li className="nav-item">
                            <a className={`nav-link ${scrolled ? 'secondary' : ''}`} href={home ? '#How' : '/#How'}>{strings[lang]['How']}</a>
                        </li> */}
                        <li className="nav-item">
                            {
                            home ?
                            <a className={`nav-link ${scrolled ? 'secondary' : ''}`} href='#Product'>{strings[lang]['Product']}</a>
                            :
                            <Link className={`nav-link ${scrolled ? 'secondary' : ''}`} state={{lang: lang}} to={{pathname: '/', hash: 'Product'}}>{strings[lang]['Product']}</Link>
                            }
                        </li>
                        {/* <li className="nav-item">
                            <a className={`nav-link ${scrolled ? 'secondary' : ''}`} href={home ? '#About' : '/#About'}>{strings[lang]['About']}</a>
                        </li> */}
                        <li className="nav-item">
                            <Link className={`nav-link ${scrolled ? 'secondary' : ''} ${pricing ? 'active' : ''}`} state={{lang: lang}} to={`/pricing`}>{strings[lang]['Pricing']}</Link>
                        </li>
                        <li className="nav-item">
                            <Link className={`nav-link ${scrolled ? 'secondary' : ''} ${contact ? 'active' : ''}`} state={{lang: lang}} to={`/contact`}>{strings[lang]['Contact']}</Link>
                        </li>
                    </ul>
                    <div className={`d-flex flex-column flex-lg-row gap-3 align-items-start align-items-lg-center ${lang === 'ar' ? 'navbar-end-rtl' : 'navbar-end'}`}>
                        {
                            lang === 'en' ?
                                <div className='nav-item dropdown'>
                                    <button className={`nav-link ${scrolled ? 'secondary' : ''} nav-btn dropdown-toggle`} data-bs-toggle="dropdown">EN 🇬🇧</button>
                                        <ul className="dropdown-menu">
                                        <li><button className="dropdown-item" onClick={() => {setLang('fr')}}>FR 🇫🇷</button></li>
                                        <li><button className="dropdown-item" onClick={() => {setLang('ar')}}>AR 🇩🇿</button></li>
                                    </ul>
                                </div>
                            : lang === 'fr' ?
                                <div className='nav-item dropdown'>
                                    <button className={`nav-link ${scrolled ? 'secondary' : ''} nav-btn dropdown-toggle`} data-bs-toggle="dropdown">FR 🇫🇷</button>
                                    <ul className="dropdown-menu">
                                        <li><button className="dropdown-item" onClick={() => {setLang('en')}}>EN 🇬🇧</button></li>
                                        <li><button className="dropdown-item" onClick={() => {setLang('ar')}}>AR 🇩🇿</button></li>
                                    </ul>
                                </div>
                            :
                                <div className='nav-item dropdown'>
                                    <button className={`nav-link ${scrolled ? 'secondary' : ''} nav-btn dropdown-toggle`} data-bs-toggle="dropdown">AR 🇩🇿</button>
                                    <ul className="dropdown-menu dropdown-menu-end">
                                        <li><button className="dropdown-item" onClick={() => {setLang('en')}}>EN 🇬🇧</button></li>
                                        <li><button className="dropdown-item" onClick={() => {setLang('fr')}}>FR 🇫🇷</button></li>
                                    </ul>
                                </div>
                        }
                        {
                            <button id='navbarCTA' className="btn btn-primary" type="button" onClick={handleClickCTA}>{strings[lang]['btn']}</button>
                        }
                    </div>
                </div>
                <div className={`d-flex flex-row gap-3 align-items-center ${lang === 'ar' ? 'navbar-end-rtl' : 'navbar-end'}`}>
                    {
                        collapsed === true ?
                        <>
                            {
                                lang === 'en' && collapsed === true ?
                                    <>
                                        <button className={`nav-link ${scrolled ? 'secondary' : ''} collapsed-only nav-btn dropdown-toggle`} data-bs-toggle="dropdown">EN 🇬🇧</button>
                                            <ul className="dropdown-menu dropdown-menu-end">
                                            <li><button className="dropdown-item" onClick={() => {setLang('fr')}}>FR 🇫🇷</button></li>
                                            <li><button className="dropdown-item" onClick={() => {setLang('ar')}}>AR 🇩🇿</button></li>
                                        </ul>
                                    </>
                                : lang === 'fr' && collapsed === true ?
                                    <>
                                        <button className={`nav-link ${scrolled ? 'secondary' : ''} collapsed-only nav-btn dropdown-toggle`} data-bs-toggle="dropdown">FR 🇫🇷</button>
                                        <ul className="dropdown-menu dropdown-menu-end">
                                            <li><button className="dropdown-item" onClick={() => {setLang('en')}}>EN 🇬🇧</button></li>
                                            <li><button className="dropdown-item" onClick={() => {setLang('ar')}}>AR 🇩🇿</button></li>
                                        </ul>
                                    </>
                                :
                                    <>
                                        <button className={`nav-link ${scrolled ? 'secondary' : ''} collapsed-only nav-btn dropdown-toggle`} data-bs-toggle="dropdown">AR 🇩🇿</button>
                                        <ul className="dropdown-menu">
                                            <li><button className="dropdown-item" onClick={() => {setLang('en')}}>EN 🇬🇧</button></li>
                                            <li><button className="dropdown-item" onClick={() => {setLang('fr')}}>FR 🇫🇷</button></li>
                                        </ul>
                                    </>
                            }
                            <button className="navbar-toggler px-0" type="button" id="collapseToggler" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
                            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" onClick={() => {setCollapsed(!collapsed)}}>
                                <Icon icon="heroicons:bars-3-16-solid" className='toggler' />
                            </button>
                        </>
                        :
                        <button type="button" className={`${lang === 'ar' ? 'close-button-rtl' : 'close-button'}`} aria-label="Close" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" onClick={() => {setCollapsed(!collapsed)}}>
                            <Icon icon="material-symbols:close" className='close'/>
                        </button>
                    }
                </div>
            </div>
        </nav>
    );
};

export default Navbar;