const strings = {
    'en': {
        'title': 'Our Fees',
        'body': 'Say goodbye to hidden fees!',
        'account': 'VaulFi Account',
        'registration': 'Open a VaulFi multi-currency account',
        'iban': 'Get your own IBAN and swift code',
        'maintenance': 'Monthly account fee',
        'receive': 'Receive Money',
        'receiveSupportedCurrency': 'Receive money in USD and EUR',
        'receiveOtherCurrency': 'Receive money in other currencies',
        'send': 'Send Money',
        'sendVaulFi': 'Send money to another VaulFi user',
        'sendBank': 'Send money globally',
        'card': 'VaulFi Card',
        'vCard': 'Issue a virtual card',
        'vCardDetails': 'Issuance, renewal, and replacement',
        'pCard': 'Issue a physical card',
        'pCardDetails': 'Issuance, renewal, and replacement',
        'spend': 'Spend Using Your VaulFi Card',
        'transactionSupportedCurrency': 'Transactions in USD and EUR',
        'transactionOtherCurrency': 'Transactions in other currencies',
        'fundingTransactions': 'Account funding transactions',
        'fundingDetails': 'Applies when you use your VaulFi card to top-up balances that are convertible to cash (such as e-wallets or money transfer services)',
        'free': 'Free',
        'currencyConversionFee': '1% currency conversion fee',
        'soon': 'Coming soon',
        'buttonText': 'Join Now',
    },
    'fr': {
        'title': "Nos Frais",
        'body': "Dites adieu aux frais cachés!",
        'account': "Compte VaulFi",
        'registration': "Ouvrir un compte multidevise VaulFi",
        'iban': "Obtenez votre propre IBAN et code swift",
        'maintenance': "Frais de compte mensuels",
        'receive': "Recevoir de L'argent",
        'receiveSupportedCurrency': "Recevoir de l'argent en USD ET EUR",
        'receiveOtherCurrency': "Recevoir de l'argent en autres devises",
        'send': "Envoyer de L'argent ",
        'sendVaulFi': "Envoyer de l'argent à un autre utilisateur VaulFi",
        'sendBank': "Envoyer de l'argent mondialement",
        'card': "Carte VaulFi",
        'vCard': "Émettre une carte virtuelle",
        'vCardDetails': "Émission, renouvellement, et remplacement",
        'pCard': "Émettre une carte physique",
        'pCardDetails': "Émission, renouvellement, et remplacement",
        'spend': "Dépensez Avec Votre Carte VaulFi",
        'transactionSupportedCurrency': "Transactions dans USD et EUR",
        'transactionOtherCurrency': "Transactions dans d'autres monnaies",
        'fundingTransactions': "Transactions de financement des comptes",
        'fundingDetails': " S'applique lorsque vous utilisez votre carte VaulFi pour recharger des soldes convertibles en espèces (comme les portefeuilles électroniques ou les services de transfert d'argent)",
        'free': "Gratuit",
        'currencyConversionFee': "1% frais de conversion de devises",
        'soon': "bientôt disponible",
        'buttonText': "Rejoindre Maintenant",
    },
    'ar': {
        'title': 'رسوم خدماتنا',
        'body': 'لا مزيد من الرسوم المخفية!',
        'account': 'حساب ڤولفاي (VaulFi)',
        'registration': 'افتح حساب ڤولفاي (VaulFi) متعدد العملات',
        'iban': 'احصل على رقم IBAN و رمز SWIFT الخاصين بيك',
        'maintenance': 'رسوم الحساب الشهرية',
        'receive': 'استقبل الأموال',
        'receiveSupportedCurrency': 'استقبل الأموال بالدولار و اليورو',
        'receiveOtherCurrency': 'استقبل الأموال بالعملات الأخرى',
        'send': 'أرسل الأموال',
        'sendVaulFi': 'أرسل الأموال إلى مستخدم ڤولفاي (VaulFi) آخر',
        'sendBank': 'أرسل الأموال لجميع أنحاء العالم',
        'card': 'بطاقة ڤولفاي (VaulFi)',
        'vCard': 'أصدر بطاقة افتراضية',
        'vCardDetails': 'الإصدار و التجديد و الاستبدال',
        'pCard': 'أصدر بطاقة بلاستيكية',
        'pCardDetails': 'الإصدار و التجديد و الاستبدال',
        'spend': 'أنفق باستخدام بطاقة ڤولفاي (VaulFi) الخاصة بك',
        'transactionSupportedCurrency': 'المعاملات بالدولار و اليورو',
        'transactionOtherCurrency': 'المعاملات بالعملات الأخرى',
        'fundingTransactions': 'معاملات تمويل الحسابات',
        'fundingDetails': 'تطبق عندما تستخدم بطاقة ڤولفاي (VaulFi) الخاصة بك لتعبئة أرصدة الحسابات القابلة للتحويل إلى نقود (مثل المحافظ الرقمية أو خدمات تحويل الأموال)',
        'free': 'مجاناً',
        'currencyConversionFee': '1% رسوم تبديل عملة',
        'soon': 'ستتوفر قريباً',
        'buttonText': 'انضم الآن',
    },
}

export default strings;