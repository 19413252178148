const strings = {
    null: {},
    'en': {
        1: {
            'contact': 'Get In Touch',
            'label': 'How would you like to connect with us?',
            'whatsapp': 'WhatsApp',
            'request':  'Submit a Request',
            'email': 'Our Email',
            'socials': 'Our Socials',
        },
        2: {
            'contact': 'Get In Touch',
            'label': "We are here for you. How can we help?",
            'name': 'Enter your name',
            'email': 'Enter your email address',
            'message': 'Go ahead, we are all ears!',
            'submit': 'Submit',
            'error': 'Please fill all fields',
            'emailError': 'Please enter a valid email',
        },
        3: {
            'successTitle': 'Message Received!',
            'successMessage': 'Thank you for reaching out to us. Our team will get back to you within 24 hours.',
        },
    },
    'fr': {
        1: {
            'contact': "Prendre Contact",
            'label': "Comment voulez-vous nous contacter?",
            'whatsapp': 'WhatsApp',
            'request':  'Soumettre une Demande',
            'email': 'Notre Email',
            'socials': 'Nos Réseaux Sociaux',
        },
        2: {
            'contact': "Prendre Contact",
            'label': "On est la pour vous. Comment pouvons-nous vous aider?",
            'name': "Entrez votre nom",
            'email': "Entrez votre Email",
            'message': "Allez y, on vous écoute",
            'submit': "Soumettre",
            'error': "Veuillez remplir tous les champs",
            'emailError': "Veuillez saisir une adresse e-mail valide",
        },
        3: {
            'successTitle': "Message Reçu!",
            'successMessage': "Merci de nous avoir contactés. Notre équipe vous répondra dans les 24 heures.",
        },
    },
    'ar': {
        1: {
            'contact': 'تواصل معنا',
            'label': 'كيف تود التواصل معنا؟',
            'whatsapp': 'واتساب',
            'request':  'إرسال طلب عبر الموقع',
            'email': 'بريدنا الإلكتروني',
            'socials': 'منصات التواصل الاجتماعي',
        },
        2: {
            'contact': 'تواصل معنا',
            'label': 'نحن هنا لخدمتك. كيف يمكننا المساعدة؟',
            'name': 'أدخل اسمك',
            'email': 'أدخل بريدك الإلكتروني',
            'message': 'تفضل نحن كلنا آذان صاغية',
            'submit': 'أرسل',
            'error': 'يرجى ملئ جميع الحقول',
            'emailError': 'يرجى إدخال بريد إلكتروني صحيح',
        },
        3: {
            'successTitle': 'وصلتنا رسالتك!',
            'successMessage': 'شكراً لتواصلك معنا. سيقوم فريقنا بالرد عليكم خلال 24 ساعة.',
        },
    },
}

export default strings;