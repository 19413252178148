import React, { useState, useEffect } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import Navbar from '../../Components/Navbar/Navbar';
import Language from '../../Modals/Language/Language';
import bootstrap from 'bootstrap/dist/js/bootstrap';
import { particlesConfig } from '../../Assets/particlesConfig';
import strings from './strings';
import { wilayas } from '../../Assets/wilayas/wilayas.js';
import { categories } from '../../Assets/categories/categories.js';
import { Icon } from '@iconify/react';
import payseraIcon from '../../Assets/icons/payseraIcon.png';
import payoneerIcon from '../../Assets/icons/payoneerIcon.png';

const Signup = ({ API_URL }) => {
    const location = useLocation();
    const [ searchParams ] = useSearchParams();
    const [lang, setLang] = useState(null);
    const [email, setEmail] = useState(null);
    const [wilaya, setWilaya] = useState(null);
    const [category, setCategory] = useState(null);
    const [currency, setCurrency] = useState('EUR');
    const [step, setStep] = useState(1);
    const currencyOptions = [{value: 'USD', label: 'USD 🇺🇸'}, {value: 'EUR', label: 'EUR 🇪🇺'}];
    const currencyOptionsAR = [{value: 'USD', label: 'دولار 🇺🇸'}, {value: 'EUR', label: 'يورو 🇪🇺'}];
    const [step1Data, setStep1Data] = useState({});
    const [paymentMethod, setPaymentMethod] = useState(null);

    useEffect(() => {
        document.title = 'VaulFi - Create an Account';

        if (!email) {
            if (location.state?.email) {
                setEmail(location.state.email);
            }
        }

        if (!lang) {
            if (location.state?.lang) {
                setLang(location.state.lang);
            }
            else if (searchParams.get('lang')) {
                setLang(searchParams.get('lang'));
            }
            else {
                let modal = bootstrap.Modal.getOrCreateInstance(document.getElementById('LanguageModal'));
                modal.show();
                window.particlesJS('language-particles', particlesConfig);
            }
        }
        
        // console.log(location.state?.email === null);
        
        // eslint-disable-next-line
    }, []);

    useEffect(()=> {
        if(document.getElementById('particles-js') !== null) {
            window.particlesJS('particles-js', particlesConfig);
        }
    }, [lang]);

    useEffect(() => {
        let el = document.getElementsByTagName('html')[0];
        if (lang === 'ar') {
            el.setAttribute('dir', 'rtl');
            el.setAttribute('lang', 'ar');
            let newEl = document.createElement('link');
            newEl.setAttribute('rel', 'stylesheet');
            newEl.setAttribute('href', 'https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/css/bootstrap-utilities.rtl.css');
            newEl.setAttribute('crossorigin', 'anonymous');
            newEl.id = 'rtlCSS';
            document.head.appendChild(newEl);
            document.title = 'ڤولفاي (VaulFi) - إنشاء حساب';
        }
        else if (lang === 'fr') {
            el.setAttribute('dir', '');
            el.setAttribute('lang', 'fr');
            document.getElementById('rtlCSS')?.remove();
            document.title = 'VaulFi - Créer un Compte';
        }
        else {
            el.setAttribute('dir', '');
            el.setAttribute('lang', 'en');
            document.getElementById('rtlCSS')?.remove();
            document.title = 'VaulFi - Create an Account';
        }
    }, [lang]);

    function clearEmail() {
        const email = document.getElementById('sEmail');
        const emailText = document.getElementById('emailText');
        emailText.textContent = '';
        emailText.classList.remove('text-danger');
        emailText.classList.remove('text-success');
        email.classList.remove('is-valid');
        email.classList.remove('is-invalid');
    }

    function validateEmail() {
        const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const email = document.getElementById('sEmail');
        const emailText = document.getElementById('emailText');

        if(!email.value) {
            clearEmail();
            return false;
        }

        if (!regexp.test(email.value)) {
            emailText.textContent = strings[lang][step]['emailInvalid'];
            emailText.classList.add('text-danger');
            emailText.classList.remove('text-success');
            email.classList.add('is-invalid');
            email.classList.remove('is-valid');
            return false;
        }
        else {
            emailText.textContent = strings[lang][step]['emailValid'];
            emailText.classList.remove('text-danger');
            emailText.classList.add('text-success');
            email.classList.add('is-valid');
            email.classList.remove('is-invalid');
            return true;
        }
    }

    function changeEmail() {
        const email = document.getElementById('sEmail');
        if (validateEmail()) {
            setEmail(email.value);
        }
    }

    function clearPhone() {
        const phone = document.getElementById('phone');
        const phoneText = document.getElementById('phoneText');
        phoneText.textContent = '';
        phoneText.classList.remove('text-danger');
        phoneText.classList.remove('text-success');
        phone.classList.remove('is-valid');
        phone.classList.remove('is-invalid');
    }

    function validatePhone() {
        const regexp = /^(\+٢١٣|٠٠٢١٣|٠|00213|\+213|0|)(5|6|7|٧|٦|٥)[0-9|٠-٩]{8}$/;
        const phone = document.getElementById('phone');
        const phoneText = document.getElementById('phoneText');

        if (!phone.value) {
            clearPhone();
            return false;
        }

        if (!regexp.test(phone.value)) {
            phoneText.textContent = strings[lang][step]['phoneInvalid'];
            phoneText.classList.add('text-danger');
            phoneText.classList.remove('text-success');
            phone.classList.add('is-invalid');
            phone.classList.remove('is-valid');
            return false;
        }
        else {
            phoneText.textContent = strings[lang][step]['phoneValid'];
            phoneText.classList.remove('text-danger');
            phoneText.classList.add('text-success');
            phone.classList.add('is-valid');
            phone.classList.remove('is-invalid');
            return true;
        }
    }

    function handleSubmit() {
        if (step === 1) {
            const emailInput = document.getElementById('sEmail');
            const name = document.getElementById('name');
            const phone = document.getElementById('phone');
            const wilayaInput = document.getElementById('wilaya').children[2];
            const categoryInput = document.getElementById('category').children[2];
            const amount = document.getElementById('amount');
    
            name.classList.remove('is-invalid');
            amount.classList.remove('is-invalid');
    
            if (!validateEmail() || !name.value || !validatePhone() || !wilaya || !category || !amount.value) {
                toast.error(`${strings[lang][step]['error']}`);
                if (!emailInput.value) {
                    emailInput.classList.add('is-invalid');
                }
                if (!name.value) {
                    name.classList.add('is-invalid');
                }
                if(!phone.value) {
                    phone.classList.add('is-invalid');
                }
                if (!wilaya) {
                    wilayaInput.classList.add('invalidInput');
                }
                if (!category) {
                    categoryInput.classList.add('invalidInput');
                }
                if (!amount.value) {
                    amount.classList.add('is-invalid');
                }
            }
            else {
                let url = `${API_URL}/log/details`;
                const data = {'email': email, 'name': name.value, 'phone': phone.value, 'wilaya': wilaya, 'account_type': category, 'monthly_amount': amount.value, 'currency': currency, 'language': lang};
                const headers = {'Content-Type': 'application/json'};

                fetch(url, {method: 'POST', body: JSON.stringify(data), headers: headers})
                .then(res => res.json())
                .then(resData => {
                    if (resData.status === 'Success') {
                        setStep1Data(data);
                        setStep(2);
                    }
                    else {
                        alert(resData.message); //TODO: update, although it should never happen
                    }
                })
                .catch(console.log);
                /* let url = `${API_URL}/redeem`;
                const data = {'code': code, 'pin': pin.value, 'card_name': card.name, 'name': name.value, 'ccp': ccp.value, 'key': key.value};
                const headers = {'Content-Type': 'application/json'};
                
                fetch(url, {method: 'POST', body: JSON.stringify(data), headers: headers})
                .then((res) => res.json())
                .then((data) => {
                    if (data.status === 'Success') {
                        if (!data.validation) {
                            toast.error(`${strings[lang]['pinError']}`);
                            pin.classList.add('is-invalid');
                        }
                        else {
                            setStep(2);
                        }
                    }
                })
                .catch(console.log); */
            }
        }
        else if (step === 3) {
            const amount = document.getElementById('amount');
            amount.classList.remove('is-invalid');

            if (!amount.value) {
                toast.error(`${strings[lang][step]['error']}`);
                
                if (!amount.value) {
                    amount.classList.add('is-invalid');
                }
            }
            else {
                let url = `${API_URL}/register`;
                const data = {...step1Data, 'topup_method': paymentMethod, 'topup_amount': amount.value, 'topup_currency': currency};
                console.log(data);
                const headers = {'Content-Type': 'application/json'};

                fetch(url, {method: 'POST', body: JSON.stringify(data), headers: headers})
                .then(res => res.json())
                .then(data => {
                    if (data.status === 'Success') {
                        setStep(4);
                    }
                    else {
                        alert(data.message); //TODO: update, although it should never happen
                    }
                })
                .catch(console.log);
            }
        }
    }

    function handlePaymentChange(method) {
        let url = `${API_URL}/log/method`;
        const data = {'email': email, 'method': method, 'language': lang};
        const headers = {'Content-Type': 'application/json'};

        fetch(url, {method: 'POST', body: JSON.stringify(data), headers: headers})
        .then(res => res.json())
        .then(data => {
            if (data.status === 'Success') {
                setPaymentMethod(method);
                setStep(3);
            }
            else {
                alert(data.message); //TODO: update, although it should never happen
            }
        })
        .catch(console.log);
    }
    
    return (
        <>
        {
            !lang?
            null
            :
            <>
            <Navbar lang={lang} setLang={setLang} signup={true}/>
            <section id="particles-js" className="container-fluid text-center">
                <div className='cover-2 mt-5 mt-lg-0 pt-5 pt-lg-0'></div>
                <div className={`z-1 position-relative redeem my-auto mx-auto py-3 col-sm-8 col-lg-5`}>
                    <div className='d-flex flex-column text-center'>
                        {
                            step === 1 ?
                                <>
                                <h2 className='mx-auto complete-main secondary-font'>{strings[lang][step]['title']}</h2>
                                <div className='mx-auto mt-1 d-flex flex-column w-75'>
                                    
                                    <div className='text-start w-100 mb-3'>
                                        <label htmlFor="sEmail" className='text-start'>{strings[lang][step]['email']}</label>
                                        <input type="email" className='form-control text-start' id='sEmail' key='emailInput' value={location.state?.email ? location.state.email : undefined} readOnly={location.state?.email} onChange={clearEmail} onBlur={changeEmail}/>
                                        <div className='form-text mt-1 text-center' id='emailText'></div>
                                    </div>

                                    <div className='text-start w-100 mb-3'>
                                        <label htmlFor="name" className='text-start'>{strings[lang][step]['name']}</label>
                                        <input type="text" className='form-control text-start' id='name' key='nameInput' onChange={() => {document.getElementById('name').classList.remove('is-invalid')}}/>
                                    </div>

                                    <div className='text-start w-100 mb-3'>
                                        <label htmlFor="phone" className='text-start'>{strings[lang][step]['phone']}</label>
                                        <div className='input-group'>
                                            <span className="input-group-text">🇩🇿 (+213)</span>
                                            <input type="tel" className="form-control text-start" id='phone' key='phoneInput' placeholder="712 34 56 78"  onChange={clearPhone} onBlur={validatePhone}/>
                                        </div>
                                        <div className='form-text mt-1 text-center' id='phoneText'></div>
                                    </div>

                                    <label htmlFor="wilaya" className='text-start'>{strings[lang][step]['wilaya']}</label>
                                    <Select
                                        className='mb-3'
                                        isClearable={false}
                                        isSearchable={true}
                                        options={wilayas[lang]}
                                        id='wilaya'
                                        onChange={(newValue) => {setWilaya(newValue.value)}}
                                        styles={{
                                            menu: (styles) => ({...styles, zIndex: 5}),
                                        }}
                                    >
                                    </Select>
                                    
                                    <label htmlFor="category" className='text-start'>{strings[lang][step]['category']}</label>
                                    <Select
                                        className='mb-3'
                                        isMulti={true}
                                        isClearable={false}
                                        isSearchable={true}
                                        options={categories[lang]}
                                        id='category'
                                        onChange={(newValues) => {
                                            if (newValues.length === 0) {
                                                setCategory(null);
                                            }
                                            else {
                                                let values = [];
                                                newValues.forEach(el => values.push(el.value));
                                                setCategory(values);
                                            }
                                        }}
                                        styles={{
                                            menu: (styles) => ({...styles, zIndex: 5}),
                                        }}
                                    >
                                    </Select>

                                    <div className='text-start w-100 mb-3'>
                                        <label htmlFor="amount" className='text-start'>{strings[lang][step]['amount']}</label>
                                        <div className="input-group">
                                            <input type="text" className='form-control text-start' id='amount' key='amountInput' inputMode='decimal' onChange={() => {document.getElementById('amount').classList.remove('is-invalid')}}/>
                                            <Select
                                                isClearable={false}
                                                isSearchable={true}
                                                options={lang === 'ar' ? currencyOptionsAR : currencyOptions}
                                                defaultValue={lang === 'ar' ? currencyOptionsAR[1] : currencyOptions[1]}
                                                onChange={(newValue) => {setCurrency(newValue.value)}}
                                            >
                                            </Select>
                                        </div>
                                    </div>

                                    <div className='mt-4'>
                                        <button className="btn btn-lg btn-primary main-btns text-center" onClick={handleSubmit}>{strings[lang][step]['buttonText']}</button>
                                    </div>
                                </div>
                                </>
                            :
                            step === 2 ?
                                <>
                                <h2 className='mx-auto complete-main secondary-font'>{strings[lang][step]['title']}</h2>
                                <div className='mx-auto mt-1 d-flex flex-column align-items-center'>
                                    <label htmlFor="paymentMethod" className='text-center mx-auto mt-4 mb-3'>{strings[lang][step]['method']}</label>
                                    <button className="btn btn-lg btn-outline-dark topup-btn mb-3 on-particles mb-3" onClick={() => {handlePaymentChange('wise');}}>
                                        <h2 className="d-flex align-items-center justify-content-center secondary-font m-auto gap-2 topup-text"><Icon icon="simple-icons:wise" className='topup-method' style={{color: '#163300'}}/> Wise</h2>
                                    </button>
                                    <button className="btn btn-lg btn-outline-dark topup-btn mb-3 on-particles mb-3" onClick={() => {handlePaymentChange('paypal');}}>
                                        <h2 className="d-flex align-items-center justify-content-center secondary-font m-auto gap-2 topup-text"><Icon icon="logos:paypal" className='topup-method'/> PayPal</h2>
                                    </button>
                                    <button className="btn btn-lg btn-outline-dark topup-btn mb-3 on-particles" onClick={() => {handlePaymentChange('paysera');}}>
                                        <h2 className="d-flex align-items-center justify-content-center secondary-font m-auto gap-2 topup-text"> <img src={payseraIcon} className='topup-method' alt='payseraIcon'/>Paysera</h2>
                                    </button>
                                    <button className="btn btn-lg btn-outline-dark topup-btn mb-3 on-particles" onClick={() => {handlePaymentChange('payoneer');}}>
                                        <h2 className="d-flex align-items-center justify-content-center secondary-font m-auto gap-2 topup-text"><img src={payoneerIcon} className='topup-method' alt='payoneerIcon'/> Payoneer</h2>
                                    </button>
                                    <button className="btn btn-lg btn-outline-dark topup-btn mb-3 on-particles" onClick={() => {handlePaymentChange('other');}}>
                                        <h2 className="d-flex align-items-center justify-content-center secondary-font m-auto gap-2 topup-text">{strings[lang][step]['other']}</h2>
                                    </button>
                                </div>
                                </>
                            :
                            step === 3 ?
                                <>
                                <h2 className='mx-auto complete-main secondary-font'>{strings[lang][step]['title']}</h2>
                                <div className='mx-auto mt-3 d-flex flex-column w-75'>
                                    
                                    <div className='text-start w-100 mb-3'>
                                        <label htmlFor="amount" className='text-start'>{strings[lang][step]['amount']}</label>
                                        <div className="input-group">
                                            <input type="text" className='form-control text-start' id='amount' key='amountInput' inputMode='decimal' onChange={() => {document.getElementById('amount').classList.remove('is-invalid')}}/>
                                            <Select
                                                isClearable={false}
                                                isSearchable={true}
                                                options={lang === 'ar' ? currencyOptionsAR : currencyOptions}
                                                defaultValue={lang === 'ar' ? currencyOptionsAR[1] : currencyOptions[1]}
                                                onChange={(newValue) => {setCurrency(newValue.value)}}
                                            >
                                            </Select>
                                        </div>
                                    </div>

                                    <div className='mt-4'>
                                        <button className="btn btn-lg btn-primary main-btns text-center" onClick={handleSubmit}>{strings[lang][step]['buttonText']}</button>
                                    </div>
                                </div>
                                </>
                            :
                                <>
                                <h2 className='mx-auto mt-3 complete-main px-2 secondary-font'>{strings[lang][step]['successTitle']}</h2>
                                <div className='mx-auto mt-4 mb-5 d-flex flex-column w-75'>
                                    <p className='text-start' style={{whiteSpace: 'pre-wrap'}}>
                                        {strings[lang][step]['dear']} {step1Data.name.split(' ')[0]},
                                        <br/><br/>
                                        {strings[lang][step]['successMessage1']}
                                        <br/><br/>
                                        <b>{strings[lang][step]['successMessage2'].toUpperCase()}</b> {strings[lang][step]['successMessage3']}
                                    </p>
                                </div>
                                </>
                        }
                    </div>
                </div>
            </section>
            </>
        }
        <Language setLang={setLang}/>
        </>
    );
};

export default Signup;