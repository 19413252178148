import React, { useEffect } from "react";
import bootstrap from 'bootstrap/dist/js/bootstrap';

const Language = ({ setLang }) => {
    useEffect(()=>{
        /* document.getElementById('waitlistModal').addEventListener('hidden.bs.modal', () => {
            ReactGA.event({
                category: 'Waitlist',
                action: 'Close',
                label: 'Waitlist Closed',
            });
        }); */
        // eslint-disable-next-line
    }, []);

    function closeModal() {
        let modal = bootstrap.Modal.getInstance(document.getElementById('LanguageModal'));
        modal.hide();
    }

    return (
        <div className="modal" id="LanguageModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1">
            <div className="modal-dialog modal-fullscreen">
                <div className="modal-content" id="language-particles">
                    <div className="modal-body d-flex flex-column gap-5 align-items-center justify-content-center">
                        <button
                            className="btn btn-lg btn-outline-light language-btn mb-3 on-particles"
                            onClick={() => {setLang('ar'); closeModal();}}
                        >
                            <h2 className="secondary-font text-center m-auto language-text">🇩🇿 العربية</h2>
                        </button>
                        <button
                            className="btn btn-lg btn-outline-light language-btn mb-3 on-particles"
                            onClick={() => {setLang('fr'); closeModal();}}
                        >
                            <h2 className="secondary-font text-center m-auto language-text">🇫🇷 Français</h2>
                        </button>
                        <button
                            className="btn btn-lg btn-outline-light language-btn mb-3 on-particles"
                            onClick={() => {setLang('en'); closeModal();}}
                        >
                            <h2 className="secondary-font text-center m-auto language-text">🇬🇧 English</h2>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Language;